<template>
    <section>
      <div class="div-block-446">
      <div class="div-block-1662">
        <p class="paragraph-125">TrueForm is a patient management platform that works with independent physicians and practitioners who provide services utilizing the TrueForm Engagement Platform. TrueForm does not directly provide medical or pharmacy services and payment does not guarantee the writing or dispensing of a prescription. Medical Services are provided via independent providers. The information provided on this website is for informational purposes and not a substitute for professional medical advice, diagnosis, or treatment. If you have questions or concerns about your health, please talk to your doctor. This site is an advertisement for services and not any specific medication.</p>
      </div>
      <div class="div-block-447">
        <div class="div-block-980">
          <a href="https://trueformrx.com/hippa" target="_blank" class="link-10">HIPAA Notice</a>
          <a href="https://trueformrx.com/privacy-policy" target="_blank" class="link-10">Privacy Policy</a>
          <a href="https://trueformrx.com/returns-refund-policy" target="_blank" class="link-10">Returns &amp; Refund Policy</a>
          <a href="https://trueformrx.com/terms-of-use" target="_blank" class="link-10">Terms Of Use</a>
          <a href="https://trueformrx.com/ccpa" target="_blank" class="link-10">CCPA Opt-Out</a>
          <a href="https://trueformrx.com/telehealth-consent" target="_blank" class="link-10">Tele Health Consent</a>
          <a href="https://trueformrx.com/safety" target="_blank" class="link-10">Safety</a>
        </div>
      </div>
      <div class="div-block-1663"><img sizes="107.21875px" srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w" alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-6">
        <div class="text-block-54"><span class="text-span-169">©</span> {{getYear}}</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
    name: 'footerComponent',
    data() {
        return {

        }
    },
    computed: {
        getYear() {
            return new Date().getFullYear();
        },
    },

}
</script>
