<template>
    <section>
    <div class="div-block-1232">
      <div class="w-layout-blockcontainer container-19 w-container">
        <a href="#" class="w-inline-block"><img sizes="128.6640625px" srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w" alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-212"></a>
      </div>
      <div class="div-block-1205">
        <div class="text-block-271">You are all set {{ first_name }}!</div>
        <p class="paragraph-70">Your information has been sent to your Weight loss doctor. They will reach out if they need anything further. Have a great day.‍</p>
        <div>
          <div class="div-block-1206"><img loading="lazy" src="@/assets/images/icon_check_purp.svg" alt="" class="image-213">
            <div class="text-block-272">Make sure to check your email for more info.</div>
          </div>
          <div class="div-block-1206"><img loading="lazy" src="@/assets/images/icon_check_purp.svg" alt="" class="image-213">
            <div class="text-block-272">Your meds will be at your door in 2-5 Days.</div>
          </div>
        </div>
        <a href="/login" class="button-50 w-button"><span class="text-span-162">Log in to Dashboard </span>-&gt;</a>
      </div>
    </div>
  </section>
</template>

<script>

export default {
    name: 'HelloWorld',
    props: ['baseUrl'],
    components: {
      
    },
    data() {
        return {
            selTime: "",
            setDate: '',
        }
    },
    computed:{
        first_name(){
            try{
                let k = localStorage.getItem("user_form");
                if(k){
                    let temp = JSON.parse(k);
                    return temp.first_name;
                }
            }catch(error){
                console.log(error);
                
            }
            return "";
        },
        
    },
    created() {
    }
}
</script>