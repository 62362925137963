<template>
<section class="login_section">
    <div class="div-block-1040"><img sizes="128.6640625px" srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w" alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-167"></div>
    <div class="w-layout-blockcontainer container-12 w-container">
        <div class="text-block-201">Forgot password?</div>
        <div class="text-block-203">Enter the email address associated with your account and we will send you a link to reset your password.</div>
        <div class="div-block-1041">
            <div class="w-form">
                <form class="form-5" @submit.prevent="onSubmit">
                    <input class="text-field-35 w-input" maxlength="256" name="email-or-phone" data-name="Email Address" placeholder="Enter your email" type="text" id="email-or-phone" v-model="email" required>
                    <button class="submit-button-6 w-button" :disabled="submitted" style="padding-top:12px">
                        <div v-if="submitted">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        </div>
                        <div  v-else>
                            <div class="">Request Reset Email</div>
                        </div>
                    </button>
                </form>
                <div class="w-form-done" style="display:block;padding-top:5px; background-color: rgb(212, 239, 238);" v-if="success">
                    <div>We have emailed you a link to reset your password.</div>
                </div>
                <div class="w-form-fail" style="display:block;" v-if="err">
                    <div>No user found with that email address.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'ResetPassword',
    props: ['baseUrl'],
    data() {
        return {
            email: null,
            submitted: false,
            success: false,
            err:false
        }
    },
    methods: {
        onSubmit() {
            this.submitted = true;
            this.err = false;
            window.axios.post(this.baseUrl + '/forgot-password', {
                email: this.email,
            }).then(response => {
                if (response.data.status == 'success') {
                    this.success= true;
                    this.submitted = false;
                } else {
                    this.submitted = false;
                    this.err = true;
                }

            });

        },
    }
}
</script>