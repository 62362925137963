<template>
<div>
    <section>
            <div class="top-nav-div">
                <div class="top-nav-elements">
                    <a href="#"  class="link-block-41 w-inline-block"><img
                            sizes="(max-width: 479px) 100vw, 128.6640625px"
                            srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w"
                            alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-294"></a>
                    <div class="form-nav-div">
                        <a href="#" 
                            class="back-link w-inline-block" v-if="currentStep < 2">
                            <div class="text-block-507">&lt;-</div>
                        </a>
                        <a href="#" @click.prevent="prevStep"
                            class="back-link w-inline-block" v-else>
                            <div class="text-block-507">&lt;-</div>
                        </a>
                        <div class="progress-div-2">
                            <div>
                                <div class="text-block-508">Step <strong>3</strong> of <strong>3</strong> Medical Questions </div>
                            </div>
                            <div class="progress-precent-div">
                                <div class="text-block-509"><strong>{{ Math.round((currentStep / 36) * 100) }}%
                                    </strong>Complete</div>
                            </div>
                        </div>
                    </div>
                    <div class="div-block-1442">
                    </div>
                </div>
            </div>
        </section>
    <section class="questions-section">
        <div class="questions-main-div">
            <div v-for="(question, index) in questionsToAsk" :key="question.id" v-bind:class="{ 'hide-element': index !== currentStep }">
                <!-- Info question type -->
                 <div style="height:30px;"></div>
                <div class="question-div" v-if="question.question_type == 'info'">
                    <div class="question-box">
                        <p style="font-size: 16px;">{{ question.label }}</p>
                    </div>
                    <div class="form-div">
                        <div class="w-form">
                            <form class="form" @submit.prevent="currentStep++;">
                                <input type="submit" data-wait="Please wait..." class="submit-button-11 w-button" value="NEXT">
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Image upload question type -->
                <div class="question-div-2" v-if="question.question_type == 'image_upload'">
                    <div class="question-box">
                        <div class="text-block-513"><strong>{{ question.label }}</strong></div>
                    </div>
                    <div class="form-div">
                        <div class="w-form">
                            <form class="form" @submit.prevent="saveImage($event, question.id)">
                                <div class="image-upload-container">
                                    <div class="upload-btn-wrapper">
                                        <input type="file" style="display:none;" id="imageUpload" accept="image/*" @change="handleImageUpload($event, question.id)">
                                        <label for="imageUpload" class="upload-button" v-if="file == null">
                                            <img src="@/assets/images/cloud_upload.png" alt="Upload Icon" class="upload-icon">
                                        </label>
                                        <label for="imageUpload" class="upload-button" style="width: 90px" v-else>
                                            <img :src="fileUrl" alt="Upload Icon" class="upload-icon">
                                        </label>
                                    </div>
                                </div>
                                <button class="form-button w-button" :disabled="imageSubmit" style="padding-top: 15px;">
                                    <div class="div-block-817" v-if="imageSubmit">
                                        <img src="@/assets/images/submitted.svg">
                                    </div>
                                    <div class="div-block-817" v-else>
                                        <div class="text-block-199">Next -></div>
                                    </div>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Radio question type -->
                <div class="question-div-2" v-if="question.question_type == 'radio'">
                    <div class="question-box">
                        <div class="text-block-513">{{ question.label }}</div>
                    </div>
                    <div class="form-div">
                        <div class="w-form">
                            <form class="form">
                                <div>
                                    <a href="#" v-for="option in question.options_array" :key="option" @click.prevent="radioChanged(question.id, option)" class="form-link w-inline-block">
                                        <div class="form-link-div">
                                            <div class="text-block-578" style="font-size: 16px;">{{option}}</div>
                                            <div class="text-block-579">&gt;</div>
                                        </div>
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Textarea question type -->
                <div class="question-div" v-if="question.question_type == 'textarea'">
                    <div class="question-box">
                        <div class="text-block-513">{{ question.label }}</div>
                        <div class="text-block-577">{{ question.label2 }}</div>
                    </div>
                    <div class="form-div">
                        <div class="w-form">
                            <form class="form" @submit.prevent="nextStep">
                                <textarea class="input-field w-input" v-model="formData[question.id]" :placeholder="question.label" required></textarea>
                                <button class="form-button w-button" :disabled="loading" style="padding-top: 15px;">
                                    <div class="div-block-817" v-if="loading">
                                        <img src="@/assets/images/submitted.svg">
                                    </div>
                                    <div class="div-block-817" v-else>
                                        <div class="text-block-199">Next -></div>
                                    </div>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Number question type -->
                <div class="question-div" v-if="question.question_type == 'number'">
                    <div class="question-box">
                        <div class="text-block-513">{{ question.label }}</div>
                    </div>
                    <div class="form-div">
                        <div class="w-form">
                            <form class="form" @submit.prevent="nextStep">
                                <input class="input-field w-input" type="number" v-model="formData[question.id]" :placeholder="question.label" required>
                                <input type="submit" data-wait="Please wait..." class="submit-button-11 w-button" value="NEXT">
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Date question type -->
                <div class="question-div" v-if="question.question_type == 'date'">
                    <div class="question-box">
                        <div class="text-block-513">{{ question.label }}</div>
                    </div>
                    <div class="form-div">
                        <div class="w-form">
                            <form class="form" @submit.prevent="nextStep">
                                <input class="input-field w-input" type="date" v-model="formData[question.id]" required>
                                <input type="submit" data-wait="Please wait..." class="submit-button-11 w-button" value="NEXT">
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Multiple choice question type -->
                <div class="question-div" v-if="question.question_type === 'multiple_choice'">
                    <div class="question-box">
                        <div class="text-block-513">{{ question.label }}</div>
                    </div>
                    <div class="form-div">
                        <div class="w-form">
                            <form class="form" @submit.prevent="nextStep">
                                <div>
                                    <label class="w-checkbox checkbox-field long-text"  v-for="option in question.options_array" :key="option">
                                    <input type="checkbox"  :value="option"  @change="updateSelectedOptions(question.id, option)" class="w-checkbox-input checkbox-13">
                                    <span class="checkbox-label-14 w-form-label" for="checkbox-3" style="font-size: 16px;">{{option}}</span>
                                    </label>
                                </div>
                                <input type="submit" data-wait="Please wait..." class="submit-button-11 w-button" value="NEXT">
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Error message -->
            <div class="question-div" v-if="error">
                <div class="w-form-fail" style="display:block;">
                    <div>This field is required</div>
                </div>
            </div>
            <div class="question-div" v-if="formSubmitError">
                <div class="w-form-fail" style="display:block;">
                    <div>{{errorMessage}}</div>
                </div>
            </div>
        </div>
    </section>
    <div style="height:280px"></div>
</div>
</template>

<script>
import questions from "../utils/weightLossQues.json"
import dependent_ques from "../utils/quesDependency.json"

export default {
    name: 'FullMedicalForm',
    props: ['baseUrl'],
    data() {
        return {
            formData: {},
            state: '',
            answeredQuestions: [],
            selectedOptions: [],
            currentStep: 0,
            loading: false,
            formSubmitError: false,
            errorMessage:"",
            questionsToAsk: questions,
            dependencies: dependent_ques,
            answerdQues: {},
            error: false,
            formResponse: null,
            fileUrl: null,
            file: null,
            imageSubmit: false,
        }
    },
    mounted() {
        console.log(this.baseUrl)
        this.getPaitient();
        this.scrolltoTop();
    },
    watch: {
        currentStep() {
            this.scrolltoTop();
        }
    },
    methods: {
        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
        prevStep(event) {
            event.preventDefault();
            let lastElement = this.answeredQuestions.pop();
            this.currentStep = lastElement;
        },
        async getPaitient() {
            const id = this.$route.query.id;
            const health_id = this.$route.query.health_id;
            if (!id || !health_id) {
                alert("Both ID and Health ID are required.");
            }
            if (localStorage.getItem('user_form')) {
                this.formResponse = JSON.parse(localStorage.getItem('user_form'));
            }else{
                const response = await window.axios.get(this.baseUrl + '/get-form?id=' + id, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                     }
                 });
                 this.formResponse = response.data.form.payload;
                 localStorage.setItem('user_form', JSON.stringify(this.formResponse))
            }
            if (this.formResponse.gender === "Male") {
                this.currentStep++;
            }
        },
        updateSelectedOptions(questionId, option) {
            if (!this.formData[questionId]) {
                this.$set(this.formData, questionId, []);
            }
            this.selectedOptions = this.formData[questionId];
            if (typeof (this.selectedOptions) == "string") {
                this.selectedOptions = this.selectedOptions.split(',').map(function (item) {
                    return item.trim();
                });
            }
            if (this.selectedOptions.includes(option)) {
                const index = this.selectedOptions.indexOf(option);
                this.selectedOptions.splice(index, 1);
            } else {
                this.selectedOptions.push(option);
            }
            this.$set(this.formData, questionId, this.selectedOptions.join(','));
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            this.file = file;
            const reader = new FileReader();
            reader.onload = () => {
                this.fileUrl = reader.result;
            };
            reader.readAsDataURL(file);
        },
        async saveImage(event, id) {
            event.preventDefault();
            if (!this.file) return;

            try {
                this.imageSubmit = true;
                let filename = this.generateUniqueId() + "." + this.file.name.split('.').pop().toLowerCase();
                const response = await window.axios.get(`${this.baseUrl}/get-signed-url?filename=${filename}&content_type=${encodeURIComponent(this.file.type)}`);
                await window.axios.put(response.data.url, this.file);
                this.formData[id] = `https://comtrueformrx.s3.us-east-2.amazonaws.com/public/user_med_images/${filename}`;
                this.imageSubmit = false;
                this.nextStep();
            } catch (error) {
                console.error('Error uploading image:', error);
                this.imageSubmit = false;
            }
        },
        generateUniqueId() {
            const combinedInfo = [
                navigator.userAgent,
                navigator.platform,
                new Date().toString(),
                Math.random()
            ].join('');
            let hash = 0;
            for (let i = 0; i < combinedInfo.length; i++) {
                hash = ((hash << 5) - hash) + combinedInfo.charCodeAt(i);
                hash |= 0; // Convert to 32bit integer
            }
            hash = Math.abs(hash);
            return hash.toString(16);
        },
        radioChanged(questionId, val) {
            this.formData[questionId] = val;
            this.nextStep();
        },
        nextStep() {
            this.error = false;
            this.answeredQuestions.push(this.currentStep);
            const currentQuestion = this.questionsToAsk[this.currentStep];
            const questionId = currentQuestion.id;
            const answer = this.formData[questionId];

            if (questionId !== 0 && (answer === "" || answer === undefined)) {
                this.error = true;
                return;
            }

            if (currentQuestion.question_type === 'multiple_choice' && Array.isArray(answer)) {
                this.answerdQues[currentQuestion.question] = answer.join(', ');
            } else if (questionId !== 0) {
                this.answerdQues[currentQuestion.question] = answer;
            }

            if (this.currentStep === this.questionsToAsk.length - 1) {
                this.checkAsync();
            } else {
                let skip = this.checkDependency(currentQuestion, answer, this.dependencies);
                this.currentStep += skip === 0 ? 1 : skip + 1;
            }
            this.selectedOptions=[];
            localStorage.setItem("answers_to_ques", JSON.stringify(this.answerdQues));
        },
        checkDependency(questionId, answer, dependencies) {
            let skipCount = 0;
            if (questionId.id == "7365199") {
                console.log(this.selectedOptions)
                if (this.selectedOptions.includes("Liver disease including nonalcoholic fatty liver disease (NAFLD)")) {
                    skipCount = 0
                } else {
                    skipCount++;
                }
            } else {
                for (const dependency of dependencies) {
                    if (dependency.dependent_on === questionId.id) {
                        if (questionId.id == "7365198") {
                            if (this.selectedOptions.includes("Tumor or infection in your brain or spinal cord")) {
                                skipCount = 0
                                break;
                            } else if (this.selectedOptions.includes("Liver disease including nonalcoholic fatty liver disease (NAFLD)")) {
                                skipCount = 1
                                break;
                            } else {
                                skipCount = 2;
                            }
                        } else if (!dependency.ask_if_val_in.includes(answer)) {
                            skipCount++;
                        } else {
                            skipCount = 0
                            break;
                        }
                    }
                }
            }

            return skipCount;
        },
        checkAsync() {
            let visit = "async_visit";
            const checkArray = [
                "Drug or alcohol misuse",
                "Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2",
                "Tumor or infection in your brain or spinal cord",
                "Elevated resting heart rate or a diagnosis of tachycardia",
                "Coronary artery disease, recent (last 24 months) heart attack, or stroke",
                "History of congestive heart failure",
                "Recent hospitalization within the last 12 months"
            ];
            let asyncState = ["AR", "DC", "DE", "ID", "KS", "LA", "MS", "NM", "RI", "WV"];
            const hasMatch = this.selectedOptions.some(value => checkArray.includes(value));

            if (hasMatch || this.answerdQues.q10_pain_medication == "Yes" || this.answerdQues.q24_blood_pressure == "141/91 - 160/100" || this.answerdQues.q24_blood_pressure == "> 160/100" || this.answerdQues.q25_heart_rate_range == "101-110 beats per minute" || this.answerdQues.q25_heart_rate_range == "> 110 beats per minute" || asyncState.includes(this.formResponse.state) || this.formResponse.age>64) {
                visit = "sync_visit";
            }
            this.submitForm(visit);
        },
        sendAsyncEmail() {
            window.axios.post(`${this.baseUrl}/async-email`,{
                id: this.$route.query.id
            });
        },
        submitForm(visit) {
            this.loading= true;
            this.formSubmitError= false;
            let payload = {
                "user_paid_for": this.formResponse.plan_selected,
                "mwl_modality": visit,
                "visit_type":"initial_visit",
                pid: this.$route.query.health_id,
                "breastfeeding_or_pregnant": "No",
                ...this.answerdQues
            }
            window.axios.post(`${this.baseUrl}/intake-form-two`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                if(response.data.status=='error'){
                    this.formSubmitError= true;
                    this.errorMessage = response.data.message
                    this.loading = false;
                }else if (response.data.data.status == 'SUCCESS') {
                    this.sendToRoute(visit);
                }else{
                    this.formSubmitError= true;
                    this.errorMessage = response.data.message
                    this.loading = false;
                }
            }).catch((error) => {
                this.formSubmitError= true;
                this.errorMessage = error.message
                this.loading = false;
            });
        },
        sendToRoute(visit){
            if(visit==='sync_visit'){
                this.$router.push({
                    name: 'Calendar',
                    query: {
                        "health_id": this.$route.query.health_id,
                        "id": this.$route.query.id,
                        "state": this.formResponse.state,
                        "visit_type": "initial_visit"
                    }
                });
            }else{
                this.$router.push({
                    name: 'MedicalFormThanks',
                    query: {
                        "health_id": this.$route.query.health_id,
                        "id": this.$route.query.id,
                        "state": this.formResponse.state
                    }
                });
                this.sendAsyncEmail();
            }
        }
    }
}
</script>

<style scoped>
/* Add any additional styles here */
.hide-element {
    display: none;
}

.image-upload-container {
    width: 100%;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
}

.upload-icon {
    width: 50px;
    height: auto;
}

/* Ensure other styles from FullMedicalForm.vue are preserved */
</style>
