import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import StyleTwo from '@/components/forms/StyleTwo.vue';
import FullMedicalForm from '@/components/forms/FullMedicalForm.vue';
import RevisitMedicalForm from '@/components/forms/revisitMedicalForm.vue';

import leadDisqualified from '@/components/common/leadDisqualified.vue';
import AppThanks from '@/components/common/appointmentThanks.vue';
import AsyncThanks from '@/components/common/asyncThanks.vue';
import MedicalFormThanks from '@/components/common/asyncThankYouPage.vue';


import Checkout from '@/components/checkouts/checkOutStyleOne.vue';
import Calendar from '@/components/calendar/CalanderMain.vue';

import CareCalendar from '@/components/calendarCare/CalanderMain.vue';
import CareThanks from '@/components/common/careThanks.vue';

import PaymentThanks from '@/components/common/paymentThanks.vue';

//dashboard pages
import LoginPage from '@/components/auth/LoginPage.vue';
import ForgotPassword from '@/components/auth/ForgotPassword.vue';
import ResetPassword from '@/components/auth/ResetPassword.vue';

//dashboard
import HomePage from '@/components/dashboard/HomePage.vue';


Vue.use(Router)

const router = new Router({
  mode: 'history',
  hash: false,
  routes: [ 
    {
      path: '/',
      name: 'QualifyingQuestion',
      component: StyleTwo,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: Checkout,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/thank-you',
      name: 'PaymentThanks',
      component: PaymentThanks,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/medical-profile',
      name: 'FullMedicalForm',
      component: FullMedicalForm,
      meta: {
        requiresAuth: false,
        guest: true
      }
    }, 
    {
      path: '/monthly-visit',
      name: 'RevisitMedicalForm',
      component: RevisitMedicalForm,
      meta: {
        requiresAuth: false,
        guest: true
      }
    }, 
    {
      path: '/care-meeting',
      name: 'CareCalendar',
      component: CareCalendar,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/care-thanks',
      name: 'CareThanks',
      component: CareThanks,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/book-meeting',
      name: 'Calendar',
      component: Calendar,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/booking-success',
      name: 'AppThanks',
      component: AppThanks,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/medicalform-thanks',
      name: 'MedicalFormThanks',
      component: MedicalFormThanks,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/medical-submitted',
      name: 'AsyncThanks',
      component: AsyncThanks,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/sorry',
      name: 'leadDisqualified',
      component: leadDisqualified,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/password-reset/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        guest: true
      }
    },
    {
      path: '/home',
      name: 'HomePage',
      component: HomePage,
      meta: {
        requiresAuth: true,
        guest: true
      }
    },
    {
      path: '/*',
      name: '404',
      component: StyleTwo,
      meta: {
        guest: true
      }
    },
  ],

})


router.beforeEach((to, from, next) => {
  // Check if the route exists
  const routeExists = router.options.routes.some(route => route.path === to.path);
  if (!routeExists) {
    next('/');
    return;
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authToken == null) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else if (to.name === "LoginPage" && store.getters.authToken !== null) {
    next({ path: '/home' });
  } else {
    next();
  }
})

export default router;
