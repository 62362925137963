<template>
<div>
    <section>
        <div class="top-nav-div">
            <div class="top-nav-elements">
                <a href="#" class="link-block-41 w-inline-block"><img sizes="(max-width: 479px) 100vw, 128.6640625px" srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w" alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-294"></a>
                <div class="form-nav-div">
                    <a href="#" @click.prevent="questionId > 1 ? questionId-- : null" class="back-link w-inline-block" :class="{ 'disabled': questionId <= 1 }">
                        <div class="text-block-507">&lt;-</div>
                    </a>
                    <div class="progress-div-2">
                        <div>
                            <div class="text-block-508">Step <strong>1</strong> of <strong>3</strong> Pre-Qualify
                            </div>
                        </div>
                        <div class="progress-precent-div">
                            <div class="text-block-509"><strong>{{ Math.round(((questionId-1) / 21) * 100) }}%
                                </strong>Complete</div>
                        </div>
                    </div>
                </div>
                <div class="div-block-1442">

                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="questions-div">
            <!-- Question 1 -->
            <div class="question-container" v-if="questionId === 1">
                <div class="question-div-2">
                    <div class="text-block-513">Who do I have the pleasure of talking to?</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="nextQuestion" class="form">
                            <div class="div-block-1476">
                                <input class="text-field-43 w-input" maxlength="256" v-model="people.first_name" placeholder="First Name" type="text" required>
                                <input class="text-field-43 w-input" maxlength="256" v-model="people.last_name" placeholder="Last Name" type="text" required>
                            </div>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 2 -->
            <div class="question-container" v-if="questionId === 2">
                <div class="question-div-2">
                    <div class="text-block-513">What was your gender at birth?</div>
                    <div class="form-subs">This will help our doctors will recommend the proper medications and
                        dosages.</div>
                    <div class="felix-form-block w-form">
                        <form class="form">
                            <a href="#" @click.prevent="setValue('gender', 'Female')" class="link-block-30 w-inline-block">
                                <div><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d8197d38318417c44d9c4_Female.svg" alt="" class="image-307">
                                    <div class="text-block-545">Female</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('gender', 'Male')" class="link-block-30 w-inline-block">
                                <div><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d81974f75b229e7b38302_Male.svg" alt="" class="image-307">
                                    <div class="text-block-545">Male</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 3 -->
            <div class="question-container" v-if="questionId === 3">
                <div class="question-div-2">
                    <div class="text-block-513">What is your date of birth?</div>
                    <div class="form-subs">Based on your date of birth our doctors can prescribe the proper
                        prescription.</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="validateDOB" class="form">
                            <div class="div-block-1470 mobile-entry form _2 birth">
                                <select v-model="people.dob.mm" class="select-field-5 felix w-select" required>
                                    <option value="">Month</option>
                                    <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                                <div class="text-block-518">-</div>
                                <input class="phone-number-field-3 form birth felix w-input" maxlength="2" pattern="[0-9]*" name="day" v-bind:class="{ 'text-danger': isError }" v-model="people.dob.dd" placeholder="Day" type="text" required>
                                <div class="text-block-518">-</div>
                                <input class="phone-number-field-3 _2 form birth felix w-input" maxlength="4" pattern="[0-9]*" name="year" v-bind:class="{ 'text-danger': isError }" v-model="people.dob.yyyy" placeholder="Year" type="text" required>
                            </div>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 4 -->
            <div class="question-container" v-if="questionId === 4">
                <div class="question-div-2">
                    <div class="text-block-513">What is your height and weight?</div>
                    <div class="form-subs">We'll use this to determine your Body mass Index (BMI) for diagnosis.
                        remember, BMI is a measure of size - not health</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="calculateBMI" class="form">
                            <div class="div-block-1478 mobile-entry form _2 height top">
                                <input class="text-field-44 height w-input" maxlength="256" v-model="people.height.ft" placeholder="Feet" type="text" required>
                                <input class="text-field-44 height w-input" maxlength="256" v-model="people.height.inch" placeholder="Inches" type="text" required>
                            </div>
                            <div class="div-block-1479 mobile-entry form _2 weight">
                                <input class="text-field-45 weight w-input" maxlength="256" v-model="people.weight" placeholder="Weight in pounds" type="text" required>
                            </div>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 5 -->
            <div class="question-container" v-if="questionId === 5">
                <div class="question-div-2">
                    <div class="text-block-513">Ok so if you weigh {{ people.weight }} lbs. now...What is your
                        desired weight?</div>
                    <div class="form-subs">It will help our doctors recommend treatments to help you achieve your
                        goals.</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="nextQuestion" class="form">
                            <div class="div-block-1481 b">
                                <div class="div-block-1480 mobile-entry form _2 weight">
                                    <input class="text-field-46 weight w-input" maxlength="256" v-model="people.desired_weight" placeholder="What is your goal weight in pounds?" type="text" required>
                                </div>
                            </div>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 6 -->
            <div class="question-container" v-if="questionId === 6">
                <div class="question-div-2">
                    <div class="text-block-513">How long have you had concerns about your weight?</div>
                    <div class="form-subs">Understanding if there's been a recent change will help us make an more
                        accurate diagnosis.</div>
                    <div class="felix-form-block w-form">
                        <form class="form">
                            <a href="#" @click.prevent="setValue('concerned_about_weight_for', 'Less than 6 months')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Less than 6 months</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('concerned_about_weight_for', '6-12 months')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">6-12 months</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('concerned_about_weight_for', '1-5 years')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">1-5 years</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('concerned_about_weight_for', 'More than 5 years')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">More than 5 years</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 7 -->
            <div class="question-container" v-if="questionId === 7">
                <div class="question-div-2">
                    <div class="text-block-513">Does your weight negatively affect your physical or mental health?
                    </div>
                    <div class="form-subs">Mental health plays a large role in your physical well being.</div>
                    <div class="felix-form-block w-form">
                        <form class="form">
                            <a href="#" @click.prevent="setValue('weight_affect_health', 'Yes, a lot')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Yes, a lot</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('weight_affect_health', 'Yes, somewhat')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Yes, somewhat</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('weight_affect_health', 'Not much')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Not much</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('weight_affect_health', 'Not at all')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Not at all</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Question 8 -->
            <div class="question-container" v-if="questionId === 8">
                <div class="question-div-2">
                    <div class="text-block-513">What is your weight loss goal?</div>
                    <div class="form-subs">This helps us to recommend the proper medication to reach your goal.
                    </div>
                    <div class="felix-form-block w-form">
                        <form class="form">
                            <a href="#" @click.prevent="setValue('weight_loss_goal', 'Lose 1-20 lbs.')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Lose 1-20 lbs.</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('weight_loss_goal', 'Lose 21-50 lbs.')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Lose 21-50 lbs.</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('weight_loss_goal', 'Lose over 50 lbs')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Lose over 50 lbs</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('weight_loss_goal', 'Haven\'t decided')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Haven't decided</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 9 -->
            <div class="question-container" v-if="questionId === 9">
                <div class="question-div-2">
                    <div class="text-block-513">What goals are you looking to accomplish?</div>
                    <div class="form-subs">Establishing clear goals will help our clinicians tailor a custom plan
                        just for you.</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="nextQuestion" class="form">
                            <label class="w-checkbox checkbox-field-15 w-clearfix" v-for="goal in goals" :key="goal">
                                <input type="checkbox" :value="goal" v-model="people.goals_are_you_looking_to_accomplish" class="w-checkbox-input checkbox-11">
                                <span class="checkbox-label-12 w-form-label">{{ goal }}</span>
                            </label>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 10 -->
            <div class="question-container" v-if="questionId === 10">
                <div class="question-div-2">
                    <div class="text-block-513">What have you tried in the past?</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="nextQuestion" class="form">
                            <label class="w-checkbox checkbox-field-15 w-clearfix" v-for="method in weightLossMethods" :key="method">
                                <input type="checkbox" :value="method" v-model="people.tried_in_past" class="w-checkbox-input checkbox-11">
                                <span class="checkbox-label-12 w-form-label">{{ method }}</span>
                            </label>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 11 -->
            <div class="question-container" v-if="questionId === 11">
                <div class="question-div-2">
                    <div class="text-block-513">Have you had prior bariatric (weight loss) surgery or any
                        abdominal/pelvic surgeries?</div>
                    <div class="felix-form-block w-form">
                        <form class="form">
                            <div class="div-block-1487">
                                <a href="#" @click.prevent="disqualifiedLead()" class="button-68 w-button">YES</a>
                                <a href="#" @click.prevent="nextQuestion()" class="button-68 w-button">NO</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 12 -->
            <div class="question-container" v-if="questionId === 12">
                <div class="question-div-2">
                    <div class="text-block-513">Do you have any of these medical conditions?</div>
                    <div class="form-subs">Any of these complications may disqualify you from weight loss medication
                        approval. "None" is the best answer.</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="checkMedicalCondition" class="form">
                            <input type="submit" class="submit-button-12 w-button" value="None (Best Answer)">
                            <label class="w-checkbox checkbox-field long-text" v-for="condition in medicalConditions" :key="condition">
                                <input type="checkbox" :value="condition" v-model="medical_condition" class="w-checkbox-input checkbox-13">
                                <span class="checkbox-label-14 w-form-label">{{ condition }}</span>
                            </label>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 13 -->
            <div class="question-container" v-if="questionId === 13">
                <div class="question-div-2">
                    <div class="text-block-513">Do you currently take any GLP Weight Loss Medications?</div>
                    <div class="felix-form-block w-form">
                        <form class="form">
                            <div class="div-block-1487">
                                <a href="#" @click.prevent="setValue('currently_taking_glp', 'Yes')" class="button-68 w-button">YES</a>
                                <a href="#" @click.prevent="setValue('currently_taking_glp', 'No')" class="button-68 w-button">NO</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 14 -->
            <div class="question-container" v-if="questionId === 14">
                <div class="question-div-2">
                    <div class="text-block-513">What medication do you currently take?</div>
                    <div class="felix-form-block w-form">
                        <form class="form">
                            <a href="#" @click.prevent="setValue('name_current_med', 'Ozempic / Wegovy')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Ozempic / Wegovy</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('name_current_med', 'Mounjaro / Zepbound')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Mounjaro / Zepbound</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('name_current_med', 'Semaglutide Compound')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Semaglutide Compound</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                            <a href="#" @click.prevent="setValue('name_current_med', 'Tirzepatide Compound')" class="link-block-30 w-inline-block">
                                <div>
                                    <div class="text-block-545">Tirzepatide Compound</div>
                                </div>
                                <div>
                                    <div class="text-block-546">-></div>
                                </div>
                            </a>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 15 -->
            <div class="question-container" v-if="questionId === 15">
                <div class="question-div-2">
                    <div class="text-block-513">What is your current dose?</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="nextQuestion" class="form">
                            <select v-model="people.phase_current_med" required class="select-field-7 form w-select">
                                <option value="" disabled selected>Select Dose</option>
                                <option v-for="dose in doses" :value="dose.phase" :key="dose.phase">{{ dose.dose }}
                                    mg</option>
                            </select>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 16 -->
            <div class="question-container" v-if="questionId === 16">
                <div class="question-div-2">
                    <div class="text-block-513">What state do you reside in?</div>
                    <div class="form-subs">We need to match you to a licensed clinician in your state.</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="checkState" class="form">
                            <select v-model="people.state" required class="select-field-7 form w-select">
                                <option value="" disabled selected>State...</option>
                                <option v-for="(value, key) in allstate" :value="value" :key="key">{{ key }}
                                </option>
                            </select>
                            <input type="submit" class="form-button w-button" value="Next ->">
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 17 -->
            <div class="question-container" v-if="questionId === 17">
                <div class="question-div-2">
                    <div class="text-block-513">What is your mobile phone number?</div>
                    <div class="form-subs">Enter the best phone to reach you on just in case your doctor has any
                        questions regarding your medical information.</div>
                    <div class="checks-div">
                        <div class="bullet-div" v-for="benefit in phoneBenefits" :key="benefit">
                            <img loading="lazy" src="@/assets/images/icon_check_purp.svg" alt="" class="image-303">
                            <div class="text-block-540">{{ benefit }}</div>
                        </div>
                    </div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="nextQuestion" class="form">
                            <div class="phone-entry-div felix">
                                <input class="phone-number-field-3 _1 form w-input" pattern="[0-9]*" maxlength="3" minlength="3" @keyup="focusNext" v-model="people.phone_part.part1" placeholder="(000)" type="text" required>
                                -
                                <input class="phone-number-field-3 form w-input" pattern="[0-9]*" maxlength="3" minlength="3" @keyup="focusNext" v-model="people.phone_part.part2" placeholder="000" type="text" required>
                                -
                                <input class="phone-number-field-3 _2 form w-input" pattern="[0-9]*" maxlength="4" minlength="4" @keyup="focusNext" v-model="people.phone_part.part3" placeholder="0000" type="text" required>
                            </div>
                            <input type="submit" class="form-button w-button" value="Next ->">
                            <p class="paragraph-103">By clicking "Next," you agree to receive communications from
                                TrueForm, including AI-generated calls, automated dialing systems, text messages,
                                emails, and other communication methods, to the contact information provided. These
                                communications may include promotions, updates, and follow-up information related to
                                your inquiry or our services. Standard message and data rates may apply. You can
                                opt-out at any time. For more details, please review our <a href="https://trueformrx.com/privacy-policy" target="_blank">Privacy
                                    Policy</a>.</p>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 18 -->
            <div class="question-container" v-if="questionId === 18">
                <div class="question-div-2">
                    <div class="text-block-513">Please enter your email</div>
                    <div class="form-subs">We will send you confirmation of approval & discount offers.</div>
                    <div class="felix-form-block w-form">
                        <form @submit.prevent="validateEmail" class="form">
                            <input class="text-field-47 email form w-input" maxlength="256" v-model="people.email" placeholder="Enter your email" type="email" v-bind:class="{ 'text-danger': isError }" required>

                            <button class="form-button w-button" :disabled="loading" style="padding-top: 15px;">
                                <div class="div-block-817" v-if="loading">
                                    <img src="@/assets/images/submitted.svg">
                                </div>
                                <div class="div-block-817" v-else>
                                    <div class="text-block-199">Next -></div>
                                </div>
                            </button>

                            <p class="paragraph-103">By clicking "Next," you agree to receive communications from
                                TrueForm, including AI-generated calls, automated dialing systems, text messages,
                                emails, and other communication methods, to the contact information provided. These
                                communications may include promotions, updates, and follow-up information related to
                                your inquiry or our services. Standard message and data rates may apply. You can
                                opt-out at any time. For more details, please review our <a href="https://trueformrx.com/privacy-policy" target="_blank">Privacy
                                    Policy</a>.</p>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Question 19 (Final) -->
            <div class="question-container" v-if="questionId === 19">
                <div class="question-div-2">
                    <div class="div-block-1450 end">
                        <div class="text-block-517">You Qualify, {{ people.first_name }}!</div>
                    </div>
                    <div class="text-block-513 end">You are the perfect candidate for our Weight Loss Program.</div>
                    <div class="form-subs end">You could lose up to 32 pounds in the next 90 days if you follow the Trueform weight loss program</div>
                    <div class="checks-div end">
                        <div class="bullet-div end"><img loading="lazy" src="@/assets/images/icon_check_purp.svg" alt="" class="image-303">
                            <div class="text-block-540">Lose weight for <strong>1/4 the price </strong>of drugs like Ozempic &amp; Mounjaro.</div>
                        </div>
                        <div class="bullet-div end"><img loading="lazy" src="@/assets/images/icon_check_purp.svg" alt="" class="image-303">
                            <div class="text-block-540">Meet with US-based doctor <strong>over quick video call.</strong></div>
                        </div>
                        <div class="bullet-div end"><img loading="lazy" src="@/assets/images/icon_check_purp.svg" alt="" class="image-303">
                            <div class="text-block-540">Get your medication deliver to your door <strong>in 3-5 days.</strong></div>
                        </div>
                        <a href="#" @click.prevent="questionId++" class="button-65 finish w-button">Start Your Weight Loss Journey <span class="text-span-157">-&gt;</span></a>
                    </div>
                    <div class="report-summary-div">
                        <div class="div-block-1637">
                            <div class="div-block-1618">
                                <div class="div-block-1596">
                                    <div class="div-block-1599">
                                        <div class="text-block-661">{{people.first_name}}, This is the start of your weight loss journey</div>
                                    </div>
                                    <div class="div-block-1627">
                                        <div class="div-block-1609">
                                            <div class="text-block-660">Your Current Weight is</div>
                                            <div class="text-block-662">{{people.weight}} <span class="text-span-152">lbs</span></div>
                                        </div>
                                        <div class="div-block-1609">
                                            <div class="text-block-660">Your Target Weight is</div>
                                            <div class="text-block-662">{{people.desired_weight}} <span class="text-span-152">lbs</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="div-block-1615">
                                    <div class="div-block-1611">
                                        <div class="div-block-1610"><img sizes="100vw" srcset="@/assets/images/TrueForm-Table-01b-p-500.png 500w, @/assets/images/TrueForm-Table-01b-p-800.png 800w, @/assets/images/TrueForm-Table-01b.png 900w" alt="" src="@/assets/images/TrueForm-Table-01b.png" loading="lazy" class="image-357"></div>
                                    </div>
                                </div>
                                <div class="div-block-1624">
                                    <div class="table-header">Weight Loss Projections<br>for <strong>{{ people.first_name }}</strong><br></div>
                                    <div class="div-block-1613">
                                        <div class="table-row-div top">
                                            <div class="div-block-1603">
                                                <div class="text-block-655 top">Program</div>
                                            </div>
                                            <div class="div-block-1604">
                                                <div class="text-block-655 top">% Loss</div>
                                            </div>
                                            <div class="div-block-1614">
                                                <div class="text-block-655 top">Weight Loss</div>
                                            </div>
                                            <div class="div-block-1636">
                                                <div class="text-block-655 top">New Weight</div>
                                            </div>
                                        </div>
                                        <div class="table-row-div">
                                            <div class="div-block-1603">
                                                <div class="text-block-655">Avg. Weight Loss on Semaglutide</div>
                                            </div>
                                            <div class="div-block-1604">
                                                <div class="text-block-637">16%</div>
                                            </div>
                                            <div class="div-block-1614">
                                                <div class="text-block-637">{{ (parseInt(people.weight) * 0.16).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                            <div class="div-block-1636">
                                                <div class="text-block-637">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.16)).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                        </div>
                                        <div class="table-row-div">
                                            <div class="div-block-1603">
                                                <div class="text-block-655">Avg. Weight Loss on Tirzepatide</div>
                                            </div>
                                            <div class="div-block-1604">
                                                <div class="text-block-637">22%</div>
                                            </div>
                                            <div class="div-block-1614">
                                                <div class="text-block-637">{{ (parseInt(people.weight) * 0.22).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                            <div class="div-block-1636">
                                                <div class="text-block-637">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.22)).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                        </div>
                                        <div class="table-row-div">
                                            <div class="div-block-1603">
                                                <div class="text-block-655">1 in 3 lose more on Semaglutide<br></div>
                                            </div>
                                            <div class="div-block-1604">
                                                <div class="text-block-637">23%</div>
                                            </div>
                                            <div class="div-block-1614">
                                                <div class="text-block-637">{{ (parseInt(people.weight) * 0.23).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                            <div class="div-block-1636">
                                                <div class="text-block-637">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.23)).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                        </div>
                                        <div class="table-row-div">
                                            <div class="div-block-1603">
                                                <div class="text-block-655">1 in 5 on TrueForm&#x27;s Program Lose</div>
                                            </div>
                                            <div class="div-block-1604">
                                                <div class="text-block-637">31%</div>
                                            </div>
                                            <div class="div-block-1614">
                                                <div class="text-block-637">{{ (parseInt(people.weight) * 0.31).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                            <div class="div-block-1636">
                                                <div class="text-block-637">{{ (parseInt(people.weight) - (parseInt(people.weight) * 0.33)).toFixed(0) }} <span class="text-span-144">lbs</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="#" @click.prevent="questionId++" class="button-65 finish w-button">Start Your Weight Loss Journey <span class="text-span-157">-&gt;</span></a>
                </div>
            </div>

            <!-- Recommendation Section -->

            <div class="question-container" v-if="questionId === 20">
                <div class="question-div-2">
                    <div class="div-block-1677">
                        <h1 class="heading-59">Recommended Medications</h1>
                        <p class="paragraph-127">Choose an option below:</p>
                    </div>
                    <div class="felix-form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="66cf09428456106a349905f0" data-wf-element-id="45278b3c-e327-6f1e-efe4-eba81fd6f199">
                            <div class="div-block-1644 products">
                                <a href="#" class="link-block-39 tirz w-inline-block" @click.prevent="setValue('plan_selected', 'Trizepatide')">
                                    <div class="div-block-1643 tirz">
                                        <div class="text-block-693"><span class="text-span-156 tirz">MOST EFFECTIVE</span></div><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d57bfc5a698f6031abeeb_yara%20placeholder.svg" alt="" class="image-360">
                                        <div class="form-product-2">Trizepatide</div>
                                        <div class="text-block-689">COMPOUND</div>
                                        <p class="paragraph-122">Same active ingredient as <strong>Mounjaro® &amp; Zepbound®</strong> for a fraction of the cost.</p>
                                        <div>
                                            <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                                <div class="bullet-text _2 form"><strong>Up to 22% </strong>Weight Loss*</div>
                                            </div>
                                            <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                                <div class="bullet-text _2 form">Works on <strong>2</strong> GLP Receptors</div>
                                            </div>
                                        </div>
                                        <div class="div-block-1645"><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d5ee2be02f050e178d918_icon_Check.svg" alt="" class="image-361">
                                            <div class="text-block-687">A once-weekly injection.</div>
                                        </div>
                                        <div class="div-block-1645"><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d5ee2be02f050e178d918_icon_Check.svg" alt="" class="image-361">
                                            <div class="text-block-687">Most Effective GLP-1</div>
                                        </div>
                                        <div class="div-block-1645"><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d5ee2be02f050e178d918_icon_Check.svg" alt="" class="image-361">
                                            <div class="text-block-687">Produced in the USA.</div>
                                        </div>
                                    </div>
                                    <div class="form-price-div felix"><img src="images/TF_TirzVial.png" loading="lazy" alt="" class="image-387">
                                        <div class="form-price felix">
                                            <div class="text-block-688">Start for only</div>
                                            <div class="amount">
                                                <div class="text-block-692">$</div>
                                                <div class="text-block-691">449</div>
                                            </div>
                                            <div class="text-block-690">a month</div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" class="link-block-39 sema w-inline-block" @click.prevent="setValue('plan_selected', 'Semaglutide')">
                                    <div class="div-block-1643 sema">
                                        <div class="text-block-693"><span class="text-span-156 sema">MOST POPULAR</span></div><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d57bfc5a698f6031abeeb_yara%20placeholder.svg" alt="" class="image-360">
                                        <div class="form-product-2">Semaglutide</div>
                                        <div class="text-block-689">COMPOUND</div>
                                        <p class="paragraph-122">Same active ingredient as <strong>Ozempic® &amp; Wegovy®</strong> for a fraction of the cost.</p>
                                        <div>
                                            <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                                <div class="bullet-text form"><strong>Up to 16%</strong> Weight Loss</div>
                                            </div>
                                            <div class="div-block-451"><img width="75.5" loading="lazy" alt="" src="@/assets/images/icon_Check-black.svg" class="image-382">
                                                <div class="bullet-text form">Works on <strong>1 </strong>GLP Receptor</div>
                                            </div>
                                        </div>
                                        <div class="form-price-div felix"><img src="images/TF_SemaVial.png" loading="lazy" alt="" class="image-387">
                                            <div class="form-price felix">
                                                <div class="text-block-688">Start for only</div>
                                                <div class="amount">
                                                    <div class="text-block-692">$</div>
                                                    <div class="text-block-691">299</div>
                                                </div>
                                                <div class="text-block-690">a month</div>
                                            </div>
                                        </div>
                                        <div class="div-block-1645"><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d5ee2be02f050e178d918_icon_Check.svg" alt="" class="image-361">
                                            <div class="text-block-687">A once-weekly injection.</div>
                                        </div>
                                        <div class="div-block-1645"><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d5ee2be02f050e178d918_icon_Check.svg" alt="" class="image-361">
                                            <div class="text-block-687">Most Prescribed GLP-1 </div>
                                        </div>
                                        <div class="div-block-1645"><img loading="lazy" src="https://uploads-ssl.webflow.com/668d55c8f0b55e9fc41fd2b8/668d5ee2be02f050e178d918_icon_Check.svg" alt="" class="image-361">
                                            <div class="text-block-687">Produced in the USA.</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="div-block-1491">
                                
                            </div>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Medication Preference Section -->
            <div class="question-container" v-if="questionId === 21">
                <div class="question-div-2">
                    <div class="div-block-1450 end"></div>
                    <div class="text-block-513 end">Do you prefer oral or injectable medication?</div>
                    <div class="form-subs end">Unless you are horrified of needles than we recommend for best
                        results to choose the once weekly injection...but it&#x27;s up to you!</div>
                    <div class="felix-form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="66cf09428456106a349905f0" data-wf-element-id="21137b53-a570-9b26-8e86-4decc411c2a3">
                            <div class="div-block-1644 products">
                                <a href="#" class="link-block-39 method inject w-inline-block" @click.prevent="setValue('drug_mode_selected', 'Injection')">
                                    <div class="text-block-715">Injection</div>
                                    <div class="text-block-716">Most Effective.</div>
                                    <div class="div-block-1659"><img src="@/assets/images/icon_check_purp.svg" loading="lazy" alt="" class="image-377">
                                        <div class="text-block-717">Take once a week.</div>
                                    </div>
                                    <div class="div-block-1659"><img src="@/assets/images/icon_check_purp.svg" loading="lazy" alt="" class="image-377">
                                        <div class="text-block-717">Painless Injection.</div>
                                    </div>
                                    <div class="div-block-1659"><img src="@/assets/images/icon_check_purp.svg" loading="lazy" alt="" class="image-377">
                                        <div class="text-block-717">Best Results.</div>
                                    </div>
                                </a>
                                <a href="#" class="link-block-39 method oral w-inline-block" @click.prevent="setValue('drug_mode_selected', 'Oral')">
                                    <div class="text-block-715">Oral</div>
                                    <div class="text-block-716">Easier on the go.</div>
                                    <div class="div-block-1659"><img src="@/assets/images/icon_check_purp.svg" loading="lazy" alt="" class="image-377">
                                        <div class="text-block-717">Take Once a day.</div>
                                    </div>
                                    <div class="div-block-1659"><img src="@/assets/images/icon_check_purp.svg" loading="lazy" alt="" class="image-377">
                                        <div class="text-block-717">Best for Travel</div>
                                    </div>
                                    <div class="div-block-1659"><img src="@/assets/images/icon_check_purp.svg" loading="lazy" alt="" class="image-377">
                                        <div class="text-block-717">Less Effective.</div>
                                    </div>
                                </a>
                            </div>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Clinical Weight Loss Coach Section -->
            <div class="question-container" v-if="questionId === 22">
                    <div class="question-div-2">
                        <div class="text-block-513 end">Do you need a clinical weight loss coach along the way?</div>
                        <div class="form-subs end">You will get on a call every 2 weeks to check your progress. Our
                            clinical weight loss coaches will provide you with a custom weight loss plan. We found that
                            this helps keep you more accountable and lose weight faster. </div>
                        <div class="felix-form-block w-form">
                            <form class="form">
                                <div class="div-block-1487">
                                    <a href="#" @click.prevent="setValue('want_coach', 'Yes')"
                                        class="button-68 yes-hilight w-button">YES</a>
                                    <a href="#" @click.prevent="setValue('want_coach', 'No')" class="button-68 w-button">NO</a>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
        </div>
    </section>
    <div style="height:280px"></div>
</div>
</template>

<script>
import state from "../utils/state.json"
export default {
    name: 'StyleTwo',
    props: ['baseUrl'],
    data() {
        return {
            allstate: state,
            questionId: 1,
            isError: false,
            loading: false,
            medical_condition: [],
            amount: 1,
            people: {
                id: '',
                first_name: '',
                last_name: '',
                gender: '',
                dob_yyyy_mm_dd: '',
                dob: {
                    mm: '',
                    dd: '',
                    yyyy: ''
                },
                height_inch: '',
                height: {
                    ft: '',
                    inch: ''
                },
                weight: '',
                desired_weight: '',
                concerned_about_weight_for: '',
                weight_affect_health: '',
                weight_loss_goal: '',
                goals_are_you_looking_to_accomplish: [],
                tried_in_past: [],
                state: '',
                phone_part: {
                    part1: '',
                    part2: '',
                    part3: ''
                },
                email: '',
                currently_taking_glp: 'No',
                name_current_med: 'none',
                phase_current_med: '',
                ip_address: '',
                aff_id: '',
                tid: '',
                utm_source: '',
                utm_medium: '',
                utm_campaign: '',
                utm_content: '',
                utm_term: '',
                plan_selected: '',
                drug_mode_selected: '',
                want_coach: '',
                age: ''
            },
            doses: [{
                    phase: 1,
                    dose: 2.5
                },
                {
                    phase: 2,
                    dose: 5
                },
                {
                    phase: 3,
                    dose: 7.5
                },
                {
                    phase: 4,
                    dose: 10
                },
                {
                    phase: 5,
                    dose: 12.5
                },
                {
                    phase: 6,
                    dose: 15
                }
            ],
            goals: [
                'Lose Weight',
                'Improve General Health',
                'Look Better',
                'Improve Confidence',
                'Increase Energy'
            ],
            weightLossMethods: [
                'Exercise',
                'Dieting',
                'Weight Loss Supplements',
                'Medical weight loss program',
                'Intermittent Fasting'
            ],
            phoneBenefits: [
                'Updates from your doctor',
                'Shipping notifications',
                'Prescription alerts',
                'Latest offers from TrueForm'
            ],
            weightLossProjections: [{
                    program: 'Avg. Weight Loss on Semaglutide',
                    percentLoss: 16
                },
                {
                    program: 'Avg. Weight Loss on Tirzepatide',
                    percentLoss: 22
                },
                {
                    program: '1 in 3 lose more on Semaglutide',
                    percentLoss: 23
                },
                {
                    program: '1 in 5 on TrueForm\'s Program Lose',
                    percentLoss: 31
                }
            ]
        };
    },
    computed: {
        medicalConditions() {
            if (this.people.gender == 'male') {
                return [
                    'Type 2 diabetes ON INSULIN',
                    'Type 1 diabetes',
                    'Diabetic retinopathy',
                    'End-stage liver disease (cirrhosis)',
                    'End-stage kidney disease (on or about to be on dialysis)',
                    'Current or prior eating disorder (anorexia/bulimia)',
                    'Current suicidal thoughts and/or prior suicidal attempt',
                    'History of organ transplant on anti-rejection medication',
                    'Severe gastrointestinal condition (gastroparesis, blockage, inflammatory bowel disease)',
                    'Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2',
                    'History of or current pancreatitis'
                ]
            }
            return [
                'Pregnant',
                'Breast Feeding',
                'Type 2 diabetes ON INSULIN',
                'Type 1 diabetes',
                'Diabetic retinopathy',
                'End-stage liver disease (cirrhosis)',
                'End-stage kidney disease (on or about to be on dialysis)',
                'Current or prior eating disorder (anorexia/bulimia)',
                'Current suicidal thoughts and/or prior suicidal attempt',
                'History of organ transplant on anti-rejection medication',
                'Severe gastrointestinal condition (gastroparesis, blockage, inflammatory bowel disease)',
                'Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2',
                'History of or current pancreatitis'
            ];
        }
    },
    methods: {
        checkState() {
            if (["MS", "LA"].includes(this.people.state)) {
                this.disqualifiedLead();
            } else {
                this.nextQuestion();
            }
        },
        nextQuestion() {
            this.questionId++;
        },
        focusNext(event) {
            const currentInput = event.target;
            const nextInput = currentInput.nextElementSibling;
            const prevInput = currentInput.previousElementSibling;
            if (event.key === "Backspace" && currentInput.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            } else if (currentInput.value.length === currentInput.maxLength && nextInput) {
                nextInput.focus();
            }
        },
        setValue(field, value) {
            this.people[field] = value;
            if (field == 'currently_taking_glp' && value == 'No') {
                this.questionId += 3;
            } else if (field == 'want_coach') {
                this.moveToCheckout();
            } else {
                this.nextQuestion();
            }
        },
        moveToCheckout() {
            if(this.people.want_coach=='Yes'){
                this.markCoach();
            }
            localStorage.setItem("user_form", JSON.stringify(this.people));
            this.$router.push({
                name: 'Checkout',
                query: {
                    "id": this.people.id,
                    "plan": this.people.plan_selected,
                    "state": this.people.state,
                    "mode": this.people.drug_mode_selected
                }
            });
        },
        markCoach(){
            window.axios.post(`${this.baseUrl}/mark-care-coach`, {
                id: this.people.id,
                want_coach: 1
            })
        },
        isValidDate(dateString) {
            const [month, day, year] = dateString.split("-").map(Number);
            if (year < 1925 || year > 2024 || month < 1 || month > 12) return false;
            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
            const maxDays = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            return day > 0 && day <= maxDays[month - 1];
        },
        validateDOB() {
            const {
                mm,
                dd,
                yyyy
            } = this.people.dob;
            const dob = `${mm}-${dd}-${yyyy}`;
            if (!this.isValidDate(dob)) {
                this.isError = true;
                return;
            }
            const today = new Date();
            const birthDate = new Date(yyyy, mm - 1, dd);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            this.people.age = age;
            if (age < 18 || age > 75) {
                this.disqualifiedLead();
            } else {
                this.nextQuestion();
            }
            this.isError = false;
        },
        calculateBMI() {
            var heightInInches = parseInt(this.people.height.ft * 12) + parseInt(this.people.height.inch);
            this.people.height_inch = heightInInches;
            let cal = (this.people.weight * 703);
            let heigh = (heightInInches * heightInInches);
            var bmi = cal / heigh;
            if (bmi < 27) {
                this.disqualifiedLead();
            } else {
                this.nextQuestion();
            }
        },

        checkMedicalCondition() {
            if (this.medical_condition.length > 0) {
                this.disqualifiedLead();
            } else {
                this.nextQuestion();
            }
        },
        disqualifiedLead() {
            this.$router.push({
                name: 'leadDisqualified'
            })
        },
        isEmailValid(email) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
            return regex.test(email);
        },
        validateEmail() {
            this.isError = false;
            this.people.phone = this.people.phone_part.part1 + this.people.phone_part.part2 + this.people.phone_part.part3;
            this.people.discount = this.$route.query.discount;
            let k = this.isEmailValid(this.people.email);
            try {
                this.people.userAgent = navigator.userAgent;
            } catch (error) {
                console.log(error);
            }
            console.log(k)
            this.people.user_type = "regular";
            this.people.frm_ref = this.$route.name;
            this.people.state_residing = this.people.state;
            if (k) {
                this.submitFormAndMoveToCheckout();
            } else {
                this.isError = true;
            }
        },
        submitFormAndMoveToCheckout() {
            this.loading = true;
            this.people.dob_yyyy_mm_dd = `${this.people.dob.yyyy}-${this.people.dob.mm}-${this.people.dob.dd}`;
            let url = `${this.baseUrl}/intake-form`;
            window.axios.post(url, this.people, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.status == 'success') {
                    this.people.id = response.data.id;
                    this.nextQuestion();
                }
                this.loading = false;
            }).catch((error) => {
                console.log(error)
                alert("An Internal error occurred")
            });
        },
        checkUrlParameters() {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.forEach((value, key) => {
                this.people[key] = value;
            });
            const phone = urlParams.get('phone');
            if (phone) {
                this.people.phone = phone;
                this.people.phone_part = {
                    part1: phone.slice(0, 3),
                    part2: phone.slice(3, 6),
                    part3: phone.slice(6)
                };
            }
            this.getAffiliate();
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            return parts.length === 2 ? parts.pop().split(';').shift() : null;
        },
        getQueryParamOrCookie(url, paramName) {
            const params = new URLSearchParams(new URL(url).search);
            return params.get(paramName) || this.getCookie(paramName);
        },
        getAffiliate() {
            const url = window.location.href;
            const keys = ['utm_campaign', 'tid', 'utm_source', 'utm_term', 'utm_medium', 'utm_content'];
            keys.forEach(key => {
                const value = this.getQueryParamOrCookie(url, key);
                if (value) {
                    this.people[key] = value;
                    if (key === 'utm_campaign') this.people.aff_id = value;
                }
            });
        },
        getIPAddress() {
            try {
                this.people.ip_address = this.$store.getters.ipAddress
                console.log(this.people.ip_address)
            } catch (error) {
                console.log(error);
            }
        },
        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
    },
    mounted() {
        this.checkUrlParameters();
        this.scrolltoTop();
        this.getIPAddress();
    },
    watch: {
        questionId() {
            this.scrolltoTop();
        }
    }
};
</script>

<style scoped>
.hide-element {
    display: none !important;
}

.text-danger {
    color: red !important;
    border: solid 1px red !important;
}
</style>
