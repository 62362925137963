<template>
<section class="login_section">
    <div class="div-block-1040"><img sizes="128.6640625px" srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w" alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-167"></div>
    <div class="w-layout-blockcontainer container-12 w-container">
        <div class="text-block-201">Reset password</div>
        <div class="text-block-203"></div>
        <div class="div-block-1041">
            <div class="w-form">
                <form class="form-5" @submit.prevent="onSubmit">
                    <p>Enter new password.</p>
                    <input class="text-field-35 w-input" maxlength="256" v-model="password" name="email-or-phone" data-name="Email Address" placeholder="Enter password" type="password" id="email-or-phone"  required>
                    <p>Re-enter password.</p>
                    <input class="text-field-35 w-input" maxlength="256" v-model="repete_password" name="email-or-phone" data-name="Email Address" placeholder="Repete password" type="password" id="email-or-phone" required>
                    
                    <button class="submit-button-6 w-button" :disabled="submitted" style="padding-top:12px">
                        <div v-if="submitted">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        </div>
                        <div v-else>
                            <div class="">Reset Password</div>
                        </div>
                    </button>
                </form>
                <div class="w-form-done" style="display:block;padding-top:5px; background-color: rgb(212, 239, 238);" v-if="success">
                    <div>Password changed successfully. Click Here to <router-link to="/login">Login</router-link>
                    </div>
                </div>
                <div class="w-form-fail" style="display:block;" v-if="err">
                    <div>Link expired!
                    </div>
                </div>
            </div>
        </div>
        </div>
</section>
</template>

 
<script>
export default {
    name: 'ResetPassword',
    props: ['baseUrl'],
    data() {
        return {
            password: '',
            repete_password:'',
            submitted: false,
            success: false,
            err:false,
            
        }
    },
    computed:{
        errorp(){
            if (this.password !== this.repete_password && this.repete_password !== '') {
             return  true;
            }
            return  false;
        }
    },
    methods: {
        onSubmit() {
            this.submitted = true;
            this.err = false;
            window.axios.post(this.baseUrl+'/password-reset', {
                password: this.password,
                token: this.$route.params.token
            }).then(response => {
                if (response.data.status == 'success') {
                    this.success= true;
                    this.submitted = false;
                } else {
                    this.submitted = false;
                    this.err = true;
                }
            });

        },
    },
}
</script>