<template>
    <div>
        <div id="mygalModal" aria-hidden="true" class="modal fade move-up in intro-modal modal-for-gal" style="display: block;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="new-modal">
                        <div class="modal_down bacground_sdo">
                            <div class="intro-modal-body">
                                <div class="intro-modal-heading"><strong>Which state you are in?</strong> </div>
                                <div class="trial-details">
                                    <form @submit.prevent="setState">
                                    <div class="preview"  style="display:flex;justify-content: center; padding-top:25px">
                                        <select v-model="state_sel" required class="text-field-47 email form w-input" style="width:80%">
                                            <option value="" disabled selected>State...</option>
                                            <option v-for="(value, key) in allstate" :value="value" :key="value">{{ key }} </option>
                                        </select>
                                    </div>
                                    <div class="footer-button">
                                        <div class="div-block-1120"  style="display:flex;justify-content: center;">
                                            <input type="submit" data-wait="Please wait..." class="form-button w-button" value="Next -&gt;">
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import state from "../utils/state.json"
    export default {
        data() {
            return {
                allstate: state,
                state_sel: "",
            }
        },
        methods: {
            setState(){
                this.$emit('setState', this.state_sel);
            }
        },
    }
    </script>
    
    <style scoped>
    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
    
    }
    
    .intro-modal-body {
        justify-content: center;
    }
    
    .intro-modal-heading {
        font-size: 18px;
        display: flex;
        justify-content: center;
    }
    
    .modal-content {
        background-color: #fefefe;
        border-radius: 10px;
        max-width: 468px;
        margin: 15% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }
    
    .dash-button {
        height: 45px;
    }
    </style>
    