<template>
    <div>
      <errorMessage v-if="error" :message="errorMessage" :errorType="errorType" @close="handleClose" />
      <askStateModel v-if="askState" @setState="setState" />
      <calendar
        v-if="active_el === 1"
        :baseURL="baseUrl"
        :visit_type="visit_type"
        @SelectedTime="SelectedTime"
        @next="moveNext"
      />
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import calendar from './CalDateSelect.vue';
  import askStateModel from './askStateModel.vue';
  import errorMessage from './errorMessage.vue';
  
  export default defineComponent({
    name: 'CalendarMain',
    components: {
      calendar,
      errorMessage,
      askStateModel
    },
    props: {
      baseUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        askState: false,
        active_el: 0,
        selectedTime: '',
        selectedDate: '',
        people: {
          id: '',
          health_id: ''
        },
        visit_type: "initial",
        error: false,
        errorMessage: "Time is no longer available. Please select a new time.",
        errorType: '',
      };
    },
    mounted() {
      this.checkState();
    },
    methods: {
      setState(statesel) {
        localStorage.setItem("userState", JSON.stringify(statesel));
        setTimeout(() => {
          this.active_el = 1;
          this.askState = false;
        }, 1000);
      },
      checkState() {
        if (this.$route.query.state) {
          localStorage.setItem("userState", JSON.stringify(this.$route.query.state));
          this.active_el = 1;
        } else {
          this.askState = true;
        }
      },
      SelectedTime(stime, sdate) {
        this.selectedTime = stime;
        this.selectedDate = sdate;
        this.scheduleAppointment();
      },
      handleClose() {
        this.active_el = 1;
        this.error = false;
      },
      scheduleAppointment() {
        const data = {
          visit_type: this.$route.query.visit_type,
          slot: JSON.parse(localStorage.getItem("app_slot")),
          state: JSON.parse(localStorage.getItem("userState")),
          ...this.people
        };
        
        window.axios.post(`${this.baseUrl}/scheduling/schedule`, data)
          .then((response) => {
            if (response.data.status === 'success') {
              this.$router.push({ name: 'AppThanks' });
            } else {
              this.active_el = 0;
              this.error = true;
              this.errorMessage = response.data.message;
              this.errorType = response.data.err_type;
            }
          })
          .catch(console.error);
      },
      moveNext() {
        this.active_el++;
      },
    },
    created() {
      if (this.$route.query.health_id && this.$route.query.id) {
        this.people.health_id = this.$route.query.health_id;
        this.people.id = this.$route.query.id;
        this.visit_type = this.$route.query.app_type;
        localStorage.setItem("userState", JSON.stringify(this.$route.query.state));
      } else {
        this.$router.push({ name: 'QualifyingQuestion' });
      }
    }
  });
  </script>