<template>
    <div class="containercal" :class="{ 'time-div-active': avltime.length > 0 }">
      <section class="description-section">
        <hgroup>
          <h4 id="scheduler" style="color:black;font-size:18px;">
            <img src="@/assets/calImages/logo_cal.png" width="56" sizes="56px" alt="" class="imagecal">
          </h4>
          <h2 id="event" class="eventcls">Schedule Weight Loss<br/> Telehealth Appointment.</h2>
          <div class="icon-text-div">
            <img src="@/assets/calImages/clock.svg" alt="clock-icon">
            <h4 id="duration">5 - 20 min</h4>
          </div>
        </hgroup>
        <p id="description" class="mobdescription">You will meet with a US Based clinician who will prescribe your weight loss medication.</p>
      </section>
      <div class="divider"></div>
      <section id="calendar-section" class="body-section" :class="{ 'flexdivs': avltime.length > 0 }">
        <h3 class="seldate-txtschedule">Select a Date & Time</h3>
        <div id="schedule-div" class="schedule-container">
          <div id="available-times-div" :class="{ 'showTimes': avltime.length > 0 }">
            <h4>
              <button v-if="ismob" class="fc-prev-button fc-button fc-button-primary" type="button" @click="avltime = []" aria-label="prev">
                <span class="fc-icon fc-icon-chevron-left"></span>
              </button>
              {{ getDateName() }}
            </h4>
            <div :key="keyval" class="date-select-button">
              <div v-for="(tim, index) in avltime" :key="index" class="time-slot" :class="{ selected: selected === index }">
                <button class="time-btn" @click="myactivity(index)">{{ tConvert(tim) }}</button>
                <button v-if="selected === index" @click="selectedtimeforapp(tim, tConvert(tim))" class="confirm-btn">Confirm</button>
              </div>
            </div>
          </div>
          <div id="calendar" :key="dateKey" class="calendar-container">
            <mycalendar
              :mindate="mindate"
              :localtimezones="userTimeZone"
              :month="month"
              :year="year"
              :disableddates="getDays"
              :availDates="availDates"
              @getFreeTime="selectedDate"
              @setTimezone="setTimezone"
              @getNewDatesForPrevious="getNewDatesForPrevious"
              @getNewDatesForNext="getNewDatesForNext"
            />
          </div>
          <div v-if="loading || timeLoading" class="schedule-overlay">
            <loadingOverlay />
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { isMobileOnly } from 'mobile-device-detect';
  import mycalendar from './calTwoBack.vue';
  import listoftimezones from './MTimezones.json';
  import statesTimeZone from './state_timezone.json';
  import loadingOverlay from '../common/loadingScreen.vue';
  import moment from 'moment-timezone';
  
  export default defineComponent({
    name: 'CalDateSelect',
    components: {
      mycalendar,
      loadingOverlay
    },
    props: {
      calset: [String, Number],
      uid: [String, Number],
      pid: [String, Number],
      baseURL: {
        type: String,
        required: true
      },
      visit_type: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        ismob: isMobileOnly,
        userTimeZone: '',
        timeLoading: false,
        availDates: [],
        loading: true,
        avltime: [],
        keyval: 0,
        mindate: '',
        dateKey: 0,
        currentDate: '',
        selected: -1,
        seldate: '',
        val: ''
      };
    },
    computed: {
      month() {
        return new Date(this.currentDate).toLocaleString('en-US', { month: 'long' });
      },
      year() {
        return new Date(this.currentDate).getFullYear();
      },
      getDays() {
        return [];
      }
    },
    methods: {
      getNewDatesForPrevious() {
        const date = new Date(this.currentDate);
        date.setMonth(date.getMonth() - 1);
        this.currentDate = date.toISOString().split('T')[0];
        this.getAvailableDates();
      },
      getNewDatesForNext() {
        const date = new Date(this.currentDate);
        date.setMonth(date.getMonth() + 1);
        this.currentDate = date.toISOString().split('T')[0];
        this.getAvailableDates();
      },
      setTimezone(val) {
        this.userTimeZone = val;
        this.getAvailableDates();
        this.keyval++;
      },
      getDateName() {
        const date = moment(this.seldate, 'YYYY/M/DD');
        const monthName = date.format('MMMM');
        const monthAbbr = date.format('MMM');
        const displayMonth = monthName.length > 6 ? monthAbbr : monthName;
        return date.format(`dddd [${displayMonth}] Do`);
      },
      selectedtimeforapp(time, converted) {
        localStorage.setItem("selTimeZone", this.userTimeZone);
        localStorage.setItem("selTime", converted);
        localStorage.setItem("app_slot", JSON.stringify(time));
        localStorage.setItem("selDate", this.val);
        this.$emit('SelectedTime', converted, this.val);
        this.loading = true;
      },
      tConvert(slot) {
        const dateTime = new Date(slot.dateIso);
        const timeZoneOptions = { timeZone: this.userTimeZone };
        const hours = dateTime.toLocaleString('en-US', { hour: 'numeric', hour12: false, ...timeZoneOptions });
        const minutes = dateTime.toLocaleString('en-US', { minute: 'numeric', ...timeZoneOptions });
        const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
        const [hour] = formattedTime.split(':').map(Number);
        const hour12 = (hour % 12 === 0) ? 12 : hour % 12;
        const period = (hour < 12) ? 'AM' : 'PM';
        const formattedMinute = (minutes < 10) ? `0${minutes}` : minutes;
        return `${hour12}:${formattedMinute} ${period}`;
      },
      myactivity(index) {
        this.selected = index;
      },
      selectedDate(data, da, yr, mon) {
        this.seldate = data;
        this.val = data;
        this.seld = `${yr} - ${mon}-${da < 10 ? '0' : ''}${da}`;
        this.checktime();
      },
      checktime() {
        this.selected = -1;
        this.timeLoading = true;
        this.avltime = [];
        const data = {
          start_date: this.seldate,
          end_date: this.seldate,
          timezone: this.userTimeZone,
          licensed_in_state: JSON.parse(localStorage.getItem("userState")),
          appt_type_id: "101487"
        };
        
        window.axios.post(`${this.baseURL}/scheduling/get_slots`, data)
          .then((response) => {
            this.avltime = response.data.availableSlotsForRange;
            this.timeLoading = false;
          })
          .catch(console.error);
      },
      isTimeZoneInList(targetTimeZone) {
        return listoftimezones.some(timezone => timezone.type === targetTimeZone);
      },
      getUserTimezone() {
        try {
          const stateAbbreviation = JSON.parse(localStorage.getItem("userState"));
          const timezone = statesTimeZone[stateAbbreviation.toUpperCase()];
          if (timezone) return timezone;
          if (this.isTimeZoneInList(Intl.DateTimeFormat().resolvedOptions().timeZone)) {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
          }
        } catch (error) {
          console.error('Error getting user timezone:', error);
        }
        return "America/New_York";
      },
      getAvailableDates() {
        const data = {
          date: this.currentDate,
          timezone: this.userTimeZone,
          appt_type_id: "384837",
          state: JSON.parse(localStorage.getItem("userState"))
        };
        
        window.axios.post(`${this.baseURL}/scheduling/get_days`, data)
          .then((response) => {
            this.availDates = response.data.daysAvailableForRange;
            this.loading = false;
            this.dateKey++;
          })
          .catch(console.error);
      }
    },
    created() {
      this.userTimeZone = this.getUserTimezone();
      this.currentDate = new Date().toISOString().split('T')[0];
      this.getAvailableDates();
    }
  });
  </script>
  
  <style scoped>
  @import "../../assets/css/main.css";
  @import "../../assets/css/styles.css";
  @import "../../assets/css/schedule.css";
  
  .schedule-container {
      position: relative;
      min-height: 300px;
  }
  
  .schedule-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
  }
  </style>