<template>
<section class="login_section">
    <div class="div-block-1040"><img sizes="128.6640625px" srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w" alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-167"></div>
    <div class="w-layout-blockcontainer container-12 w-container">
        <h1 class="heading-45">Great to see you again.</h1>
        <div class="text-block-201">Let&#x27;s get you logged in</div>
        <div class="text-block-203">New user? <a href="/" class="link-12">take the quiz <span class="text-span-163">-&gt;</span></a>
        </div>
        <div class="div-block-1041">
            <div class="w-form">
                <form class="form-5" @submit.prevent="onSubmit">
                    <input class="text-field-35 w-input" maxlength="256" v-model="email" name="email-or-phone" data-name="Email Address" placeholder="Enter your email" type="text" id="email-or-phone" required>
                    <input class="text-field-35 w-input" maxlength="256" v-model="password" name="password" data-name="Password" placeholder="Password" type="password" id="password" required>
                    <button class="submit-button-6 w-button" :disabled="submitted" style="padding-top:12px">
                        <div v-if="submitted">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="blue" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        </div>
                        <div  v-else>
                            <div class="">Log In</div>
                        </div>
                    </button>

                    <div class="text-block-203">Forgot Password? <a href="/forgot-password" class="link-12">click here to reset <span class="text-span-163">-&gt;</span></a>
                    </div>
                </form>

                <div class="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail" style="display:block;"  v-if="err">
                    <div v-html="errmsg">
                </div>
            </div>
        </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'LoginPage',
    props: ['baseUrl'],
    data() {
        return {
            email: null,
            password: null,
            submitted: false,
            err: false,
            errmsg: "",
        }
    },
    methods: {
        onSubmit() {
            this.submitted = true;
            this.err = false;
            window.axios.post(this.baseUrl + '/login', {
                password: this.password,
                email: this.email,
            }).then(response => {
                if (response.data.status == 'success') {
                    this.$store.dispatch("doLogin", response.data);
                    this.$router.replace("/home");
                } else {
                    this.submitted = false;
                    this.err = true;
                    this.errmsg = response.data.message;
                }
            }).catch(error => {
                console.log(error.message)
                this.submitted = false;
                this.err = true;
                this.errmsg = 'Email or password entered is incorrect. Please try again or <router-link to="/forgot-password">reset your password.</router-link>'
            });

        },
    }
}
</script>
