<template>
<section>
    <div class="div-block-1232">
        <div class="w-layout-blockcontainer container-19 w-container">
            <a href="#" class="w-inline-block"><img sizes="128.6640625px" srcset="images/TF_logo-01-p-500.png 500w, images/TF_logo-01.png 1158w" alt="" src="images/TF_logo-01.png" loading="lazy" class="image-212"></a>
        </div>
        <div class="div-block-1205">
            <div class="text-block-271">One last step {{ first_name }}!</div>
            <p class="paragraph-70">Before your Doctor visit we need to collect some basic medical information. This is the same form you would fill out when seeing a new doctor in person. After complete you can schedule your teledoc visit.‍</p>

            <a href="#" @click="pushToRoute($event)" class="button-50 w-button"><span class="text-span-162">Get Started</span>-&gt;</a>
        </div>
    </div>
</section>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
        first_name() {
            try {
                if (this.$route.query.first_name) {
                    return this.$route.query.first_name
                }
            } catch (error) {
                console.log(error);
            }
            return "";
        }
    },
    methods: {
        pushToRoute(event) {
            event.preventDefault();
            this.$router.push({
                name: 'FullMedicalForm',
                query: {
                    "health_id": this.$route.query.health_id,
                    "id": this.$route.query.id
                }
            });
        }
    },
    created() {}
}
</script>
