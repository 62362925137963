<template>
    <div class="fc fc-media-screen fc-direction-ltr fc-theme-standard">
      <div class="fc-header-toolbar fc-toolbar" style="justify-content: center;">
        <button @click="subtractMonth" class="fc-prev-button fc-button fc-button-primary" style="width:10%;font-weight: bold;" type="button" aria-label="prev">
          <span class="fc-icon fc-icon-chevron-left"></span>
        </button>
        <div class="fc-toolbar-chunk" style="width:80%;display:flex;justify-content:center;">
          <h2 class="fc-toolbar-title" style="font-weight: bold;">{{ month }} {{ year }}</h2>
        </div>
        <button @click="addMonth" class="fc-next-button fc-button fc-button-primary" style="width:10%;font-weight: bold" type="button" aria-label="next">
          <span class="fc-icon fc-icon-chevron-right"></span>
        </button>
      </div>
      <div class="fc-view-harness fc-view-harness-passive">
        <div class="icon-text-div" style="padding-bottom: 25px; display: flex; justify-content: center;">
          <img src="@/assets/calImages/timezone-globe.svg" alt="clock-icon" width="100px;">
          <select class="timezoneSelect-select" v-model="localtimezonesmy" @change="setTimeZone">
            <option class="timezoneSelect-options" value="" disabled>Select Your Timezone</option>
            <option class="timezoneSelect-options" v-for="timezone in selectabetimezone" :value="timezone.type" :key="timezone.type">
              {{ timezone.other }}
            </option>
          </select>
        </div>
        <div class="fc-daygrid fc-dayGridMonth-view fc-view">
          <table class="fc-scrollgrid">
            <thead>
              <tr class="fc-scrollgrid-section fc-scrollgrid-section-sticky">
                <td>
                  <div class="fc-scroller-harness">
                    <div class="fc-scroller" style="overflow: visible;">
                      <table class="fc-col-header" style="width: 339px;">
                        <colgroup></colgroup>
                        <tbody>
                          <tr>
                            <th v-for="day in days" :key="day" class="fc-col-header-cell fc-day">
                              <div class="fc-scrollgrid-sync-inner">
                                <a class="fc-col-header-cell-cushion">{{ day }}</a>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr class="fc-scrollgrid-section">
                <td>
                  <div class="fc-scroller-harness">
                    <div class="fc-scroller">
                      <div class="fc-daygrid-body fc-daygrid-body-unbalanced fc-daygrid-body-natural" style="width: 339px;">
                        <table class="fc-scrollgrid-sync-table" style="width: 339px;">
                          <colgroup></colgroup>
                          <tbody>
                            <tr v-for="(week, weekIndex) in mydays" :key="weekIndex">
                              <td v-for="(day, dayIndex) in getwee(week.week)" :key="dayIndex" 
                                  class="fc-daygrid-day fc-day" 
                                  :class="{'fc-day-disabled': !checkifbookabke(day), 'fc-day-today': checkDate(day), 'fc-day-past': isPastDate(day), 'fc-day-future': isFutureDate(day)}"
                                  style="cursor:pointer;"
                                  @click="ClickedMe(day)">
                                <div class="fc-daygrid-day-frame fc-scrollgrid-sync-inner">
                                  <div class="fc-daygrid-day-top" 
                                       :class="{'is-bookable': checkifbookabke(day), 'is-makelessvis': !checkifbookabke(day), 'is-selected': active_el == day}">
                                    <a class="fc-daygrid-day-number">
                                      <span>{{ day }}</span>
                                    </a>
                                  </div>
                                  <div class="fc-daygrid-day-events"></div>
                                  <div class="fc-daygrid-day-bg"></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import listoftimezones from './MTimezones.json';
  import moment from 'moment-timezone';
  
  export default defineComponent({
    name: 'CalTwoBack',
    props: {
      mindate: {
        type: String,
        required: true
      },
      disableddates: {
        type: Array,
        default: () => []
      },
      localtimezones: {
        type: String,
        required: true
      },
      availDates: {
        type: Array,
        required: true
      },
      month: {
        type: String,
        required: true
      },
      year: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        active_el: 0,
        today: moment(),
        selectabetimezone: listoftimezones,
        mindt: new Date(),
        localtimezonesmy: '',
        dateContext: moment(),
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      };
    },
    computed: {
      mydays() {
        const days = [];
        const firstDayOfMonth = this.firstDayOfMonth;
        let week = '';
        let counter = 0;
  
        // Add empty days for the beginning of the month
        for (let i = 0; i < firstDayOfMonth; i++) {
          week += (week ? ',' : '') + '.';
          counter++;
        }
  
        // Add days of the month
        for (let i = 1; i <= this.daysInMonth; i++) {
          week += (week ? ',' : '') + i;
          counter++;
  
          if (counter === 7 || i === this.daysInMonth) {
            days.push({ week });
            week = '';
            counter = 0;
          }
        }
  
        return days;
      },
      daysInMonth() {
        return this.dateContext.daysInMonth();
      },
      currentDate() {
        return this.dateContext.get('date');
      },
      firstDayOfMonth() {
        const firstDay = moment(this.dateContext).startOf('month');
        return firstDay.weekday();
      },
    },
    methods: {
      setTimeZone() {
        this.$emit("setTimezone", this.localtimezonesmy);
      },
      checkifbookabke(day) {
        const codate = `${this.year}-${moment().month(this.month).format("MM")}-${day.padStart(2, '0')}`;
        return this.isDateAvailable(codate);
      },
      isDateAvailable(dateToCheck) {
        return this.availDates.includes(dateToCheck);
      },
      checkDate(day) {
        const today = moment();
        return this.month === today.format('MMMM') && 
               this.year === today.year() && 
               parseInt(day) === today.date();
      },
      isPastDate(day) {
        const date = moment(`${this.year}-${this.month}-${day}`, 'YYYY-MMMM-D');
        return date.isBefore(moment(), 'day');
      },
      isFutureDate(day) {
        const date = moment(`${this.year}-${this.month}-${day}`, 'YYYY-MMMM-D');
        return date.isAfter(moment(), 'day');
      },
      ClickedMe(day) {
        if (this.checkifbookabke(day)) {
          const seldate = `${this.year}/${moment().month(this.month).format("M")}/${day}`;
          this.active_el = day;
          this.$emit('getFreeTime', seldate, day, this.year, moment().month(this.month).format("MM"));
        }
      },
      getwee(str) {
        return str.split(',');
      },
      addMonth() {
        this.$emit("getNewDatesForNext");
      },
      subtractMonth() {
        this.$emit("getNewDatesForPrevious");
      },
      isTimeZoneInList(targetTimeZone) {
        return this.selectabetimezone.some(timezone => timezone.type === targetTimeZone);
      }
    },
    created() {
      this.mindt = moment(this.mindate).subtract(1, 'day').toDate();
      this.localtimezonesmy = this.isTimeZoneInList(this.localtimezones) ? this.localtimezones : "America/New_York";
    }
  });
  </script>
  
  <style scoped>
  .is-selected {
    font-weight: 700 !important;
    border-radius: 50%;
    color: #fff !important;
    background-color: #6d22ff;
    height: 30px;
    width: 30px;
  }
  
  select.timezoneSelect-select {
    border: 0;
    font-size: 1rem;
    font-weight: 300;
    color: darkgray;
  }
 
  
  .fc-day-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  
  .fc-day-past {
    color: #999;
  }
  
  .fc-day-future {
    font-weight: bold;
  }
  </style>