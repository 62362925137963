<template id="modal-template">
    <div>
      <div  class="modal">
        <h2>Error!</h2>
        <p>{{message}}</p>
        <router-link to="/checkout?plan=none&phase=1" class="form-button w-button" v-if="errorType=='subscription'" style="width: 100%;">Click Here To Select Plan</router-link>
        <router-link to="/" class="form-button w-button" v-else-if="errorType=='user'" style="width: 100%;">Click Here Singup</router-link>
        <a @click="reloadWindow($event)" class="form-button w-button" v-else style="width: 100%;">Schedule another time.</a>
      </div>      
    </div>
  </template>
 <script>
 export default {
     props: ["message","errorType"],
    data() {
      return {
        showModal: false
      };
    },
    methods: {
      reloadWindow(event){
        event.preventDefault();
        window.location.reload();
      },
      toggleModal() {
        this.$emit("Close");
      }
    },
}
</script>
  <style scoped>
 .modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 380px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align the content */
}

.modal-button {
  display: inline-block; /* Use inline-block to center the button */
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
