import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios-auth'
import router from '../router/index.js'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    userData: null,
    loggingIn: false,
    transaction: null,
    subscription: null,
    appointments: null,
    lab: null,
    priscription: null,
    shipping: null,
    tracking: null,
    ipAddress: null,
    medForm: null
  },
  mutations: {
    loginStart: state => state.loggingIn = true,
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    updateUserData: (state, userData) => {
      state.userData = userData;
    },
    logout: (state) => {
      state.accessToken = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
    },
    SaveTransactions: (state, trans) => {
      state.transaction = trans.transactions;
      state.subscription = trans.subscriptions;
      state.appointments = trans.appointment;
      state.lab = trans.lab;
      state.tracking = trans.tracking;
      state.priscription = trans.priscription;
      state.shipping = trans.shipping
      state.medForm = trans.medForm;
    },
    setIpAddress(state, ipAddress) {
      state.ipAddress = ipAddress;
    },
  },
  actions: {
    saveTransaction({ commit }, data) {
      commit('SaveTransactions', data);
    },
    doLogin({ commit }, loginData) {
      commit('updateAccessToken', loginData.token.token);
      commit('updateUserData', loginData.user);
    },
    logout({ commit },data) {
      return new Promise((resolve, reject) => {
        let token = data.token;
        axios.get("/logout", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        }).then(res => {
          commit('updateAccessToken', null);
          console.log(res);
          localStorage.removeItem('_ymain_key_');
          router.push({ name: 'LoginPage' });
        }).catch(error => {

          reject(error)
        });
      });
    },
    async getIpAddress({ commit }) {
      try {
        const response = await axios.get('https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=text');
        commit('setIpAddress', response.data);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    },
  },
  getters: {
    user(state) {
      return state.userData
    },
    isAuthenticated(state) {
      return state.accessToken !== null
    },
    isAdmin(state) {
      return state.userData.is_admin === 1
    },
    authToken(state) {
      return state.accessToken
    },
    transaction(state){
      return state.transaction;
    },
    subscription(state){
      return state.subscription;
    },
    appointment(state){
      return state.appointments;
    },
    prescription(state){
      return state.priscription;
    },
    tracking(state){
      return state.tracking;
    },
    lab(state){
      return state.lab;
    },
    shipping(state){
      return state.shipping;
    },
    medForm(state){
      return state.medForm;
    },
    ipAddress: (state) => state.ipAddress,
  },
  plugins: [
    createPersistedState({
      key: '_ymain_key_',
    })
  ],
})