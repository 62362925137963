<template>
    <div>
        <stateRestriction v-if="showModel" @close="showModel = false"></stateRestriction>
        <section class="section_checkout-nav">
            <div class="top-nav-elements">
                <a href="#" class="link-block-41 w-inline-block"><img sizes="(max-width: 479px) 100vw, 128.6640625px"
                        srcset="@/assets/images/TF_logo-01-p-500.png 500w, @/assets/images/TF_logo-01.png 1158w" alt=""
                        src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-294"></a>
                <div class="form-nav-div">
                    
                    <div class="progress-div-2">
                        <div>
                            <div class="text-block-508">Step 2 of 3 Checkout</div>
                        </div>
                        <div class="progress-precent-div"></div>
                    </div>
                </div>
                <div class="div-block-1442">
                </div>
            </div>
        </section>
        <section class="section_checkout-body">
            <div class="checkout-left-div">
                <div class="text-block-698"><strong class="bold-text-4">Start your weight loss journey!</strong></div>
                <div class="text-block-699">Today is the last day you will struggle with your weight.</div>
                <div class="checkout-summary-div mobile">
                    <div class="summary-top">
                        <div class="text-block-700">Treatment summary<br></div>
                        <div class="text-block-701">
                            <a href="#" class="link-43">Edit</a>
                        </div>
                    </div>
                    <div class="order-div">
                        <div class="div-block-960 new">
                            <div class="div-block-976">
                                <div class="div-block-974">
                                    <div class="checkout-product-div oral" v-if="paymentInfo.drug_mode == 'Oral'"></div>
                                    <div class="checkout-product-div" v-else></div>
                                    <div class="div-block-975">
                                        <div class="text-block-161">1</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-block-87">{{ paymentInfo.medication }} Program</div>
                                </div>
                            </div>
                            <div class="text-block-141 new strikethrough" style="display: block;"
                                v-if="paymentInfo.discount > 0">${{ paymentInfo.amount }}</div>
                            <div class="text-block-347">${{ totalAmount }}</div>
                        </div>
                        <div class="text-block-702">Billed &amp; shipped monthly</div>
                    </div>
                    <div class="div-block-962 new">
                        <div class="text-block-703">Shipping</div>
                        <div class="text-block-163">FREE</div>
                    </div>
                    <div class="div-block-962 new" v-if="paymentInfo.discount > 0">
                        <div class="text-block-703">Discount</div>
                        <div class="text-block-163">${{ paymentInfo.discount }}</div>
                    </div>
                    <div class="div-block-1289">
                        <div class="text-block-348">Total</div>
                        <div class="text-block-348">${{ totalAmount }}</div>
                    </div>
                    <div class="text-block-702" v-if="paymentInfo.discount > 0">You&#x27;ll be charged
                        ${{ paymentInfo.amount }} Month 2 onwards.</div>
                    <div class="promo-div">
                        <form id="email-form-7" @submit.prevent="validateCoupon">
                            <div class="form-block-10 checkout w-form">
                                <input class="tf_textfield promo checkout w-input" v-model="paymentInfo.couponCode"
                                    maxlength="256" placeholder="Promo Code" type="text" required>
                                <div class="promo-button-div">
                                    <input type="submit" class="button-80 promo w-button" value="SUBMIT">
                                </div>
                            </div>
                            <div class="w-form-fail" style="display: block;" v-if="!couponValid">
                                <div>Oops! coupon is not valid.</div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-div">
                    <div class="text-block-705">Express Payment</div>
                    <div ref="expresscheckoutelement"></div>
                    <div style="height:20px"></div>
                    <form @submit.prevent="getStripeToken">
                        <div class="card-form w-form">
                            <div class="card-top-div">
                                <div class="text-block-707">Credit card</div>
                                <div class="cards-div"><img width="Auto" loading="lazy" alt=""
                                        src="@/assets/images/visa.png" class="image-7"><img width="35" loading="lazy"
                                        alt="" src="@/assets/images/mastercard.png" class="image-7"><img width="35"
                                        loading="lazy" alt="" src="@/assets/images/AmEx.png" class="image-7"><img
                                        width="35" loading="lazy" alt="" src="@/assets/images/discover.png"
                                        class="image-7"></div>
                            </div>
                            <div class="card-fields-div">
                                <label class="w-checkbox checkbox-field-17" style="padding-left: 0px;"><span class="w-form-label" for="checkbox-5">Shipping details</span></label>
                                <div class="address-div" style="width:100%">
                                        <google-places-autocomplete
                                        :api-key="googleMapsApiKey"
                                        @address-updated="getAddressData"
                                        />
                                    </div>
                                
                               
                                <!-- Card Field -->
                                <div ref="card" class="my-input"></div>
                                <!-- Checkbox 1: Refund Policy and Terms -->
                                <label class="w-checkbox">
                                    <input type="checkbox" id="checkbox-1" name="checkbox-1" data-name="Checkbox 1"
                                        class="w-checkbox-input" required>
                                    <span class="checkbox-label-16 w-form-label" for="checkbox-1">
                                        I have read and agree to the website
                                        <a href="https://www.trueformrx.com/returns-refund-policy" target="_blank">Refund Policy</a> and
                                        <a href="https://www.trueformrx.com/terms-of-use" target="_blank">Terms and Conditions</a>
                                    </span>
                                </label>

                                <!-- Checkbox 2: Age Certification -->
                                <label class="w-checkbox">
                                    <input type="checkbox" id="checkbox-2" name="checkbox-2" data-name="Checkbox 2"
                                        class="w-checkbox-input" required>
                                    <span class="checkbox-label-16 w-form-label" for="checkbox-2">
                                        I certify that I am at least 18 years of age
                                    </span>
                                </label>

                                <!-- Checkbox 3: Terms and Policies -->
                                <label class="w-checkbox">
                                    <input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3"
                                        class="w-checkbox-input" required>
                                    <span class="checkbox-label-16 w-form-label" for="checkbox-3">
                                        I agree to the <a href="https://www.trueformrx.com/terms-of-use" target="_blank">Terms of Use</a>,
                                        <a href="https://www.trueformrx.com/privacy-policy" target="_blank">Privacy Policy</a>,
                                        <a href="https://www.trueformrx.com/hippa" target="_blank">HIPPA Notice</a> and
                                        <a href="https://www.trueformrx.com/telehealth-consent" target="_blank">Telehealth Consent</a>
                                    </span>
                                </label>

                                <!-- Checkbox 4: Billing Agreement -->
                                <label class="w-checkbox" v-if="paymentInfo.medication=='Semaglutide' && paymentInfo.discount>200">
                                    <input type="checkbox" id="checkbox-4" name="checkbox-4" data-name="Checkbox 4"
                                        class="w-checkbox-input" required>
                                    <span class="checkbox-label-16 w-form-label" for="checkbox-4">
                                        I agree to be billed for ${{totalAmount}} the first month and $349 for each month thereafter
                                        until canceled.
                                        If I cancel after the first month, I will forfeit the discount and be charged
                                        the full price.
                                        <a href="#">Terms of condition</a> of this program
                                    </span>
                                </label>
                            </div>

                            <div class="apple-pay-radio-div">
                                <label class="w-radio">
                                    <input type="radio" data-name="Radio 2" id="radio-2" name="radio-2"
                                        class="w-form-formradioinput w-radio-input" value="Radio 2">
                                    <span class="w-form-label" for="radio-2">Apple Pay</span>
                                </label>
                                <img src="@/assets/images/Apple_Pay_logo.svg" loading="lazy" alt="" class="image-365">
                            </div>
                            <div class="w-form-fail" style="display:block" v-if="paymentError">
                                <div>{{ errorMessage }}.</div>
                            </div>
                        </div>
                        <div class="mobile-pay-button-div"></div>
                        <button class="button-80  w-button" style="padding-top: 15px;">
                            <div class="div-block-817" v-if="submitted">
                                <img src="@/assets/images/submitted.svg">
                            </div>
                            <div class="div-block-817" v-else>
                                <div class="text-block-199">Pay Now</div>
                            </div>
                        </button>
                    </form>
                </div>

                <div class="desktop-pay-button-div">
                    <div class="div-block-1657">
                        <div class="text-block-713">In order for us to send you your medication, you MUST complete the
                            brief medical form immediately after checking out.</div>
                    </div>
                    <div class="secure-payment-div"><img width="130" loading="lazy" alt=""
                            src="@/assets/images/paymentsecure.422c0e1.png"></div>
                </div>

            </div>
            <div class="checkout-right-div">
                <div class="mobile-pay-button-div">
                    <div class="div-block-1657">
                        <div class="text-block-713">In order for us to send you your medication, you MUST complete the
                            brief medical form immediately after checking out.</div>
                    </div>
                    <div class="secure-payment-div"><img width="130" loading="lazy" alt=""
                            src="@/assets/images/paymentsecure.422c0e1.png"></div>
                </div>
                <div class="checkout-summary-div desktop">
                    <div class="summary-top">
                        <div class="text-block-700">Treatment summary<br></div>
                        <div class="text-block-701">
                            <a href="#" class="link-43">Edit</a>
                        </div>
                    </div>
                    <div class="order-div">
                        <div class="div-block-960 new">
                            <div class="div-block-976">
                                <div class="div-block-974">
                                    <div class="checkout-product-div oral" v-if="paymentInfo.drug_mode == 'Oral'"></div>
                                    <div class="checkout-product-div" v-else></div>
                                    <div class="div-block-975">
                                        <div class="text-block-161">1</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-block-87">{{ paymentInfo.medication }} Program</div>
                                </div>
                            </div>
                            <div class="text-block-141 new strikethrough" style="display:block"
                                v-if="paymentInfo.discount > 0">${{ paymentInfo.amount }}</div>
                            <div class="text-block-347">${{ totalAmount }}</div>
                        </div>
                        <div class="text-block-702">Billed &amp; shipped monthly</div>
                    </div>
                    <div class="div-block-962 new">
                        <div class="text-block-703">Shipping</div>
                        <div class="text-block-163">FREE</div>
                    </div>
                    <div class="div-block-962 new" v-if="paymentInfo.discount > 0">
                        <div class="text-block-703">Discount</div>
                        <div class="text-block-163">${{ paymentInfo.discount }}</div>
                    </div>
                    <div class="div-block-1289">
                        <div class="text-block-348">Total</div>
                        <div class="text-block-348">${{ totalAmount }}</div>
                    </div>
                    <div class="text-block-702" v-if="paymentInfo.discount > 0">You&#x27;ll be charged
                        ${{ paymentInfo.amount }} Month 2 onwards.</div>
                    <div class="promo-div">
                        <form id="email-form-7" @submit.prevent="validateCoupon">
                            <div class="form-block-10 checkout w-form">
                                <input class="tf_textfield promo checkout w-input" v-model="paymentInfo.couponCode"
                                    maxlength="256" placeholder="Promo Code" type="text" id="Promo-Code-2" required>
                                <div class="promo-button-div">
                                    <input type="submit" class="button-80 promo w-button" value="SUBMIT">
                                </div>
                            </div>

                            <div class="w-form-fail" style="display: block;" v-if="!couponValid">
                                <div>Oops! coupon is not valid.</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import {
    loadStripe
} from '@stripe/stripe-js';
import GooglePlacesAutocomplete from '../common/GooglePlacesAutocomplete.vue'
import stateRestriction from '../common/stateRestriction.vue'
import plans from "../utils/price_plan.json"
import coupons from "../utils/coupons.json"

export default {
    name: 'CheckoutComponent',
    components: {
        GooglePlacesAutocomplete,
        stateRestriction
    },
    props: ['baseUrl', 'stripeKey','googleMapsApiKey'],
    data() {
        return {
            all_plans: plans,
            all_coupons: coupons,
            submitted: false,
            paymentError: false,
            showModel: false,
            stripe: null,
            card: null,
            elements: null,
            expressCheckoutElement: null,
            customerInfo: {
                id: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
            },
            paymentInfo: {
                id: '',
                cardToken: '',
                amount: 499,
                discount: 0,
                medication: '',
                couponCode: '',
                drug_mode: '',
                pricing_plan: 'price_1PvfK3GUo9kSCmX1xgHRvMui',
                couponId: '',
                paymentMethod: null
            },
            couponValid: true,
            couponApplied: false,
            errorMessage: '',
        };
    },
    computed: {
        totalAmount() {
            return (this.paymentInfo.amount - this.paymentInfo.discount).toFixed(2);
        },
    },
    mounted() {
        this.checkRouteForDiscount();
        this.initializeStripe();
    },
    methods: {
        async initializeStripe() {
            this.stripe = await loadStripe(this.stripeKey);
            let elements = this.stripe.elements();
            this.card = elements.create('card', {
                style: {
                    base: {
                        fontSize: '16px',
                        color: '#000',
                        fontWeight: 400,
                        '::placeholder': {
                            color: '#000',
                        },
                    },
                    invalid: {
                        iconColor: '#FFC7EE',
                        color: 'red',
                    },
                },
            });
            this.card.mount(this.$refs.card);

            this.intilizeExpress();
        },
        intilizeExpress(){
            this.elements = this.stripe.elements({
                mode: 'subscription',
                amount: this.totalAmount * 100,
                currency: 'usd',
                setupFutureUsage: 'off_session',
                paymentMethodCreation: 'manual'
            });
            this.expressCheckoutElement = this.elements.create('expressCheckout');
            this.expressCheckoutElement.mount(this.$refs.expresscheckoutelement);
            this.expressCheckoutElement.on('click', this.handleClick);
            this.expressCheckoutElement.on('confirm', this.handleConfirm);
        },
        async handleClick(event) {
            const options = {
                shippingAddressRequired: true,
                shippingRates: [
                    {
                        id: 'free-shipping',
                        displayName: 'Free shipping',
                        amount: 0,
                        deliveryEstimate: {
                            maximum: { unit: 'day', value: 7 },
                            minimum: { unit: 'day', value: 1 }
                        }
                    },
                ],
                lineItems: [
                    { name: `${this.paymentInfo.plan} Program`, amount: this.totalAmount * 100 }
                ]
            };
            event.resolve(options);
        },
        async handleConfirm(event) {
            this.customerInfo.addressLine1 =event.shippingAddress.address.line1;
            this.customerInfo.addressLine2 =event.shippingAddress.address.line2;
            this.customerInfo.city = event.shippingAddress.address.city;
            this.customerInfo.state = event.shippingAddress.address.state;
            this.customerInfo.zip = event.shippingAddress.address.postal_code;

            const { error, paymentMethod } = await this.stripe.createPaymentMethod({
                elements: this.elements,
                params: {
                    billing_details: event.billingDetails
                }
            });

            if (error) {
                console.error('Error finalizing payment:', error);
                this.errorMessage = error.message;
                this.submitted = false;
                event.reject();
            } else {
                this.paymentInfo.paymentMethod = paymentMethod.id;
                console.log(JSON.stringify(this.paymentInfo))
                const response = await window.axios.post(`${this.baseUrl}/create-payment-profile`, this.paymentInfo);
                if (response.data.status === 'success') {
                    this.pushToRoute(response.data);
                    this.updateAddress();
                    event.resolve();
                } else {
                    this.errorMessage = error.message;
                    this.submitted = false;
                    event.reject();
                }
            }
        },        
        checkRouteForDiscount() {
            const id = this.$route.query.id;
            if (id) {
                this.customerInfo.id = id;
                this.paymentInfo.id = id;
            } else {
                this.$router.push({
                    name: 'QualifyingQuestion'
                })
            }

            const plan = this.$route.query.plan;
            console.log(plan)
            if (plan) {
                if (plan == 'Trizepatide') {
                    this.paymentInfo.amount = 449;
                    this.paymentInfo.medication = "Trizepatide";
                    this.paymentInfo.pricing_plan = this.all_plans.tri;
                } else {
                    this.paymentInfo.amount = 299;
                    this.paymentInfo.medication = "Semaglutide";
                    this.paymentInfo.pricing_plan = this.all_plans.sema;
                }
            } else {
                this.paymentInfo.medication = 'Semaglutide'
            }

            const routeDiscount = this.$route.query.discount;
            if (routeDiscount && coupons[routeDiscount]) {
                let couponDetails = coupons[routeDiscount];
                if (couponDetails.value > 200) {
                    if (this.paymentInfo.medication === 'Semaglutide') {
                        this.paymentInfo.amount = 349;
                        this.paymentInfo.pricing_plan = this.all_plans.sema349;
                        this.paymentInfo.discount = parseFloat(couponDetails.value);
                        this.paymentInfo.couponId = couponDetails.id;
                    } else {
                        this.paymentInfo.discount = 0;
                        this.paymentInfo.couponId = null;

                    }
                } else {
                    this.paymentInfo.discount = parseFloat(couponDetails.value);
                    this.paymentInfo.couponId = couponDetails.id;
                }
            }

            const mode = this.$route.query.mode;
            if (mode) {
                this.paymentInfo.drug_mode = parseFloat(mode);
            }
            console.log(this.paymentInfo.drug_mode)
        },
        async validateCoupon() {
            if (this.couponApplied) return;

            try {
                const response = await window.axios.post(`${this.baseUrl}/validate-coupon`, {
                    coupon: this.paymentInfo.couponCode
                });
                if (response.data.status === 'success') {
                    this.couponValid = true;
                    this.couponApplied = true;
                    this.paymentInfo.couponId = response.data.coupon_id;
                    if (response.data.c_type == 1) {
                        this.paymentInfo.discount = response.data.c_val;
                    } else {
                        this.paymentInfo.discount = Math.round((this.people.amount * response.data.c_val) / 100);
                    }
                    this.intilizeExpress();
                } else {
                    this.couponValid = false;
                }
            } catch (error) {
                console.error('Error validating coupon:', error);
                this.errorMessage = 'An error occurred while validating the coupon.';
            }
        },

        getAddressData(address) {
            console.log(address)
            if (["MS", "LA"].includes(address.state)) {
                this.showModel = true;
            } else {
                this.customerInfo.addressLine1 =address.street;
                this.customerInfo.addressLine2 = address.address_line2;
                this.customerInfo.city = address.city;
                this.customerInfo.state = address.state;
                this.customerInfo.zip = address.zip;
            }
        },

        updateAddress() {
            window.axios.post(this.baseUrl + '/save-address', this.customerInfo);
        },
        async getStripeToken() {
            try {
                this.submitted = true;
                this.paymentError = false;
                const result = await this.stripe.createToken(this.card);
                if (result.error) {
                    this.errorMessage = result.error.message;
                    this.paymentError = true;
                    this.submitted = false;
                } else {
                    this.paymentInfo.cardToken = result.token.id;
                    // Proceed with payment processing
                    this.processPayment();
                }
            } catch (error) {
                console.error('Error getting Stripe token:', error);
                this.errorMessage = 'An error occurred while processing your payment.';
                this.paymentError = true;
                this.submitted = false;
            }
        },

        processPayment() {
            // Implementation for processing payment
            this.paymentError = false;
            window.axios.post(this.baseUrl + '/create-payment-profile', this.paymentInfo).then((response) => {
                console.log(response)
                if (response.data.status == 'success') {
                    this.updateAddress();
                    this.pushToRoute(response.data);
                    this.submitted = false;
                } else {
                    this.paymentError = true;
                    this.errorMessage = response.data.message;
                    this.submitted = false;
                }
            }).catch((error) => {
                console.log(error);
                this.paymentError = true;
                this.errorMessage = error.message;
                this.submitted = false;
            });
        },
        pushToRoute(data) {
            this.$router.push({
                name: 'PaymentThanks', 
                query: {
                    "id": this.$route.query.id,
                    "health_id": data.health_id
                }
            });
        },
    }
};
</script>

<style scoped>
.my-input {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 9px;
    margin-bottom: 15px;
    height: '55px';
}

.tf_textfield.w-input.StripeElement {
    align-content: center;
}

.strikethrough {
    text-decoration: line-through;
    text-decoration-color: red;
    text-decoration-thickness: 2px;
}

.promo-div {
    justify-content: space-between;
    display: block;
}

.form-block-10.checkout {
    width: 100%;
    display: flex;
    gap: 10px;
}
</style>
