<template>
  <div id="app">
    <router-view :baseUrl="baseUrl" :stripeKey="StripeKey" :googleMapsApiKey="googleMapsApiKey"></router-view>
    <footer-section v-if="reportPage"/>
  </div>
</template>

<script>
import FooterElement from './components/common/footerElement.vue'
const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
const StripeKey= process.env.VUE_APP_STRIPE_KEY;
const GoogleMapsApiKey= process.env.VUE_APP_GOOGLE_KEY;
export default {
  name: 'App',
  components: {
    'footer-section': FooterElement,
  },
  data() {
        return {
            baseUrl: `${apiEndpoint}/api`,
            StripeKey: StripeKey,
            googleMapsApiKey: GoogleMapsApiKey
        }
    },
    computed: {
        isLoggedin() {
            return this.$store.getters.isAuthenticated;
        },
        reportPage() {
            let rname = this.$route.name;
            const noShow = ['CareCalendar', 'Calendar']
            if (noShow.includes(rname)) {
                return false;
            } else {
                return true;
            }

        }
      }
}
</script>
