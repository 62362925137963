<template>
    <div>
        <div>
    <div class="form-3-header-div">
      <div class="div-block-966">
        <a href="#" class="w-inline-block"><img sizes="(max-width: 479px) 114.3671875px, 128.6640625px" srcset="@/assets/images/TF_logo-01-p-500.png 500w, images/TF_logo-01.png 1158w" alt="" src="@/assets/images/TF_logo-01.png" loading="lazy" class="image-30"></a>
      </div>
    </div>
  </div>
  <section>
    <div class="div-block-1679">
      <div class="w-layout-blockcontainer container-24 w-container">
        <div class="div-block-1681">
          <div class="text-block-736">You are all set {{ first_name }}!</div>
          <p class="paragraph-128">Based on your responses, the initial <strong>virtual doctor visit is not required.</strong><br></p>
        </div>
        <div class="form-block-6 w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-13" data-wf-page-id="66f77a34729523245d03e0bd" data-wf-element-id="f0b084a1-2197-8581-32e2-631e9a155df6">
            <div class="bullet-points">
              <div class="div-block-1682"><img loading="lazy" src="https://cdn.prod.website-files.com/668d55c8f0b55e9fc41fd2b8/66e063d26925cab97883e1fe_stepsimage_teledoc.png" alt="" class="image-384">
                <div>
                  <div class="text-block-735">Doctor will review &amp; prescribe.</div>
                  <div class="text-block-737">Our clinicians will review your info &amp; send script to partner pharmacy.</div>
                </div>
              </div>
              <div class="div-block-1682"><img loading="lazy" src="https://cdn.prod.website-files.com/668d55c8f0b55e9fc41fd2b8/66c742632bf62043d3fad823_icon_van.png" alt="" class="image-384">
                <div>
                  <div class="text-block-735">Express Shipping</div>
                  <div class="text-block-737">We will ship your medication express so you can start ASAP!</div>
                </div>
              </div>
              <div class="div-block-1682"><img loading="lazy" src="@/assets/images/icon_doc.png" alt="" class="image-384">
                <div>
                  <div class="text-block-735">Unlimited Clinical Support</div>
                  <div class="text-block-737">Once the medicine arrives you will gain unlimited access to doctors by phone &amp; email to answer any questions.</div>
                </div>
              </div>
              
            </div>
            <div class="div-block-1680">
              <a href="/login" class="button-81 w-button">Log in to Dashboard</a>
            </div>
          </form>
          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
        <div class="div-block-1678"></div>
      </div>
    </div>
  </section>
    </div>
</template>

<script>

export default {
    name: 'HelloWorld',
    props: ['baseUrl'],
    components: {
      
    },
    data() {
        return {
            selTime: "",
            setDate: '',
        }
    },
    computed:{
        first_name(){
            try{
                let k = localStorage.getItem("user_form");
                if(k){
                    let temp = JSON.parse(k);
                    return temp.first_name;
                }
            }catch(error){
                console.log(error);
                
            }
            return "";
        },
        
    },
    created() {
    }
}
</script>